import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Spinner } from '@chakra-ui/react'
import Login from './pages/auth/Login'
import Me from './pages/Me'
import CVList from './pages/CV'
import CVAdd from './pages/CV/CVAdd'
import CVEdit from './pages/CV/CVEdit'
import NotFound from './pages/NotFound'
import ProtectedRouteAuth from './protectedRoutes/Auth'
import ProtectedRouteUser from './protectedRoutes/User'
import { useAuth } from './context/AuthContext'
import UserList from './pages/User'
import UserAdd from './pages/User/UserAdd'
import UserEdit from './pages/User/UserEdit'

export const AppRoutes = () => {
  const { isFetched, isLogin } = useAuth()

  if (!isFetched) {
    return <Spinner />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRouteAuth isLoggedIn={isLogin} />}>
          <Route path="/signin" element={<Login />} />
        </Route>
        <Route element={<ProtectedRouteUser isLoggedIn={isLogin} />}>
          <Route path="/" element={<CVList />} />
          <Route path="/cv" element={<CVList />} />
          <Route path="/cv/add" element={<CVAdd />} />
          <Route path="/cv/:cvId/edit" element={<CVEdit />} />
          <Route path="/me" element={<Me />} />
          <Route path="/user" element={<UserList />} />
          <Route path="/user/add" element={<UserAdd />} />
          <Route path="/user/:userId/edit" element={<UserEdit />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
