import { useCallback, useEffect, useState } from 'react'
import { Button, useToast } from '@chakra-ui/react'
import RouterLink from '../../components/RouterLink'
import queryString from 'query-string'
import TableList from '../../components/TableList'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useConfirmation } from '../../context/ConfirmationContext'
import CardForm from '../../components/CardForm'
import TableListTitle from '../../components/TableList/TableListTitle'
import TableListPagination from '../../components/TableList/TableListPagination'
import { useQueryParams } from '../../hooks/queryParams'
import {
  PaginatorInfo,
  User,
  useDeleteUserMutation,
  useListUsersQuery,
} from '../../generated/graphql'
import UserFilter from './UserFilter'
import { useAuth } from '../../context/AuthContext'

const UserList: React.FC = () => {
  const location = useLocation()

  const toast = useToast()

  const { me } = useAuth()

  const { pathname } = location

  const { confirmation, setIsLoading, close } = useConfirmation()

  const navigate = useNavigate()

  const [paginator, setPaginator] = useState<PaginatorInfo>()

  const { queryParams } = useQueryParams<{
    page?: string
    key?: string
  }>()

  const { data, refetch, loading } = useListUsersQuery()

  useEffect(() => {
    refetch({
      page: parseInt((queryParams?.page || 1) as string),
      key: queryParams?.key ? `%${queryParams.key}%` : undefined,
    })
  }, [queryParams, refetch])

  const [deleteUser] = useDeleteUserMutation()

  useEffect(() => {
    setPaginator(data?.listUsers.paginatorInfo)
  }, [data])

  const deleteRow = useCallback(
    async (id: string) => {
      try {
        await deleteUser({ variables: { id } })

        toast({
          description: 'User has been deleted successfully!',
          status: 'success',
        })

        refetch()
      } catch (err) {
        console.log('Delete User Error: ', err)

        toast({
          description: 'Error',
          status: 'error',
        })
      } finally {
      }
    },
    [refetch, toast, deleteUser]
  )

  const onPageChange = (page: number) => {
    const url = queryString.stringifyUrl({
      url: pathname,
      query: {
        page,
      },
    })

    navigate(url)
  }

  return (
    <>
      <CardForm px="0" py="0">
        <TableListTitle
          title="User"
          actionComponents={
            <Button as={RouterLink} to="/user/add" size="sm">
              Create User
            </Button>
          }
        />
        {data && data.listUsers.data.length > 0 && (
          <TableListPagination
            paginationProps={{
              paginator: paginator as PaginatorInfo,
              onPrev: (value: any) => {
                if (onPageChange) {
                  onPageChange(value)
                }
              },
              onNext: (value: any) => {
                if (onPageChange) {
                  onPageChange(value)
                }
              },
            }}
            filterComponents={
              <UserFilter
                onSubmit={(data) => {
                  const url = queryString.stringifyUrl({
                    url: pathname,
                    query: { ...data, page: 1 },
                  })

                  navigate(url)
                }}
                value={queryParams}
              />
            }
          />
        )}
        <TableList
          loading={loading}
          resources={data?.listUsers.data as User[]}
          dataArrayRender={(user: User) => {
            return [user.name, user.email, user.level]
          }}
          headings={['Name', 'Email', 'Level']}
          actions={(resource) => (
            <>
              <Button as={Link} to={`/user/${resource.id}/edit`} variant="link">
                Edit
              </Button>
              {me?.id !== resource.id && (
                <Button
                  onClick={() => {
                    confirmation({
                      description: 'Are you sure want to delete the data?',
                      callback: async () => {
                        setIsLoading(true)
                        await deleteRow(resource.id)
                        setIsLoading(false)
                        close()
                      },
                    })
                  }}
                  variant="link"
                >
                  Delete
                </Button>
              )}
            </>
          )}
        />
      </CardForm>
    </>
  )
}

export default UserList
