import { ModalDocumentFormProps } from './interface'
import VisaForm from './VisaForm'
import ModalComp from '../Modal'

const ModalVisaForm: React.FC<ModalDocumentFormProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <ModalComp isOpen={isOpen} onClose={onClose} headerText="Visa">
      <VisaForm {...props} />
    </ModalComp>
  )
}

export default ModalVisaForm
