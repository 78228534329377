import { useState } from 'react'
import axios from 'axios'
import cookie from 'js-cookie'

export function useUpload() {
  const [uploadedKey, setUploadedKey] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const uploadImage = async (file: File) => {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('file', file)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'X-XSRF-TOKEN': cookie.get('XSRF-TOKEN') || '',
      },
      withCredentials: true,
    }

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_PUBLIC_DOMAIN}/api/upload-image`,
        formData,
        config
      )
      return result.data
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  return {
    uploadImage,
    uploadedKey,
    isLoading,
  }
}
