import { ModalLanguageFormProps } from './interface'
import LanguageForm from './LanguageForm'
import ModalComp from '../Modal'

const ModalLanguageForm: React.FC<ModalLanguageFormProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <ModalComp isOpen={isOpen} onClose={onClose} headerText="Language">
      <LanguageForm {...props} />
    </ModalComp>
  )
}

export default ModalLanguageForm
