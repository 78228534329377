import { FormProps } from './interface'
import CardForm from '../../components/CardForm'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react'
import { UserLevel } from '../../generated/graphql'
import { useForm } from 'react-hook-form'
import TextRequired from '../../components/TextRequired'

const UserForm: React.FC<FormProps> = ({ onSubmit, data, loading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      name: data?.name || '',
      email: data?.email || '',
      password: '',
      confirmPassword: '',
      level: data?.level || UserLevel.Admin,
    },
  })

  const isEdit = data !== undefined

  return (
    <>
      <CardForm>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={errors.hasOwnProperty('name')}>
            <FormLabel htmlFor="name">
              Name of User <TextRequired>*</TextRequired>
            </FormLabel>
            <Input
              id="name"
              placeholder="Enter the name of user"
              {...register('name', { required: true })}
            />
            {errors.name && errors.name.type === 'required' && (
              <FormErrorMessage>Name is Required!</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={errors.hasOwnProperty('email')}>
            <FormLabel htmlFor="email">
              Email <TextRequired>*</TextRequired>
            </FormLabel>
            <Input
              id="email"
              type="email"
              placeholder="Enter email"
              {...register('email', { required: true })}
            />
            {errors.email && errors.email.type === 'required' && (
              <FormErrorMessage>Email is Required!</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={errors.hasOwnProperty('password')}>
            <FormLabel htmlFor="password">
              Password{!isEdit && <TextRequired>*</TextRequired>}
            </FormLabel>
            <Input
              id="password"
              placeholder="Password"
              type="password"
              {...register('password', { required: !isEdit, minLength: 8 })}
            />
            {errors.password && errors.password.type === 'required' && (
              <FormErrorMessage>Password is Required!</FormErrorMessage>
            )}
            {errors.password && errors.password.type === 'minLength' && (
              <FormErrorMessage>Minimum 8 chars!</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={errors.hasOwnProperty('confirmPassword')}>
            <FormLabel htmlFor="confirmPassword">
              Confirm Password{!isEdit && <TextRequired>*</TextRequired>}
            </FormLabel>
            <Input
              id="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              {...register('confirmPassword', {
                required: watch('password') !== '',
                validate: (value) => value === watch('password'),
              })}
            />
            {errors.confirmPassword &&
              errors.confirmPassword.type === 'required' && (
                <FormErrorMessage>
                  Confirm Password is required!
                </FormErrorMessage>
              )}
            {errors.confirmPassword &&
              errors.confirmPassword.type === 'validate' && (
                <FormErrorMessage>Password should be the same!</FormErrorMessage>
              )}
          </FormControl>

          <FormControl isInvalid={errors.hasOwnProperty('level')}>
            <FormLabel htmlFor="name">
              Level <TextRequired>*</TextRequired>
            </FormLabel>
            <Select id="name" {...register('level', { required: true })}>
              <option value={UserLevel.Admin}>Admin</option>
              <option value={UserLevel.Superadmin}>Super Admin</option>
            </Select>
            {errors.level && errors.level.type === 'required' && (
              <FormErrorMessage>Level is Required!</FormErrorMessage>
            )}
          </FormControl>
          <Button type="submit">Submit</Button>
        </form>
      </CardForm>
    </>
  )
}

export default UserForm
