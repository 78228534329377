export enum FieldType {
  IMAGE,
  BOOLEAN,
  PHONE,
  COUNTRY,
  SELECT,
  DATEPICKER,
  EMAIL,
  TEXTAREA,
  NUMBER,
  TEXT,
  BOOLEANTEXT,
}

export interface ICvFormField {
  name: string
  label: string
  type: FieldType
  imageUrl?: string // only for image type
  options?: { value: string; label: string }[]
  alwaysShow?: boolean
  required?: boolean
  readOnly?: boolean
  templates: string[]
  placeholder?: string
}
