import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { FormProps, ILanguageItem } from './interface'
import ActionButtons from '../../ActionButtons'
import TableList from '../../../../components/TableList'
import ModalLanguageForm from '../../../../components/ModalLanguageForm'
import TableListTitle from '../../../../components/TableList/TableListTitle'
import { useConfirmation } from '../../../../context/ConfirmationContext'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import { IFormData } from '../../../../components/ModalLanguageForm/interface'

const defaultLanguages = [
  'English',
  'German',
  'French',
  'Italian',
  'Spanish',
  'Russian',
]

const Language: React.FC<FormProps> = ({ onBack, onNext, data }) => {
  const {
    isOpen: isOpenForm,
    onOpen: onOpenForm,
    onClose: onCloseForm,
  } = useDisclosure()

  const [languages, setLanguages] = useState<ILanguageItem[]>(
    data
      ? data.map((elm) => {
          return {
            rowItemId: uuidv4(),
            data: { ...elm, isDefault: defaultLanguages.includes(elm.name) },
          }
        })
      : defaultLanguages.map((elm) => ({
          rowItemId: uuidv4(),
          data: {
            name: elm,
            isDefault: true,
            spoken: null,
            written: null,
            reading: null,
          },
        }))
  )

  const { confirmation, close } = useConfirmation()

  const [selectedIndex, setSeletedIndex] = useState<number>(-1)
  const [selectedItem, setSelectedItem] = useState<ILanguageItem | null>(null)

  const onLocalSubmit = (itemData: IFormData) => {
    let nextLanguages = null
    if (selectedItem !== null) {
      nextLanguages = update(languages, {
        [selectedIndex]: {
          data: { $set: itemData },
        },
      })
    } else {
      nextLanguages = update(languages, {
        $push: [{ rowItemId: uuidv4(), data: itemData }],
      })
    }

    setLanguages(nextLanguages)
    onCloseForm()
    setSelectedItem(null)
  }

  const deleteItem = (index: number) => {
    const nextlanguages = update(languages, {
      $splice: [[index, 1]],
    })

    setLanguages(nextlanguages)
  }

  return (
    <>
      <TableListTitle
        title="Language List"
        actionComponents={
          <Button size="sm" onClick={onOpenForm}>
            Add Language
          </Button>
        }
      />
      <TableList
        resources={languages}
        dataArrayRender={(language) => {
          return [
            language.data.name,
            language.data.spoken || '',
            language.data.written || '',
            language.data.reading || '',
          ]
        }}
        headings={['Language Name', 'Spoken', 'Written', 'Reading', '']}
        actions={(language, index) => (
          <Flex gap={4}>
            <Button
              onClick={() => {
                setSeletedIndex(index)
                setSelectedItem(language)
                onOpenForm()
              }}
              size="sm"
            >
              Edit
            </Button>
            {!language.data.isDefault && (
              <Button
                variant="link"
                onClick={() => {
                  confirmation({
                    description: 'Are you sure want to delete the data?',
                    callback: () => {
                      deleteItem(index)
                      close()
                    },
                  })
                }}
              >
                Delete
              </Button>
            )}
          </Flex>
        )}
      />
      <ActionButtons
        mt={10}
        onBack={onBack}
        onNext={() => onNext(languages.map((elm) => elm.data))}
      />
      <ModalLanguageForm
        data={selectedItem?.data}
        isOpen={isOpenForm}
        onClose={() => {
          onCloseForm()
          setSelectedItem(null)
        }}
        onSubmit={onLocalSubmit}
      />
    </>
  )
}

export default Language
