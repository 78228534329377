import { Fragment, useState } from 'react'
import { FormProps, IFormData } from './interface'
import Steps from './Steps'
import PersonalData from './screens/PersonalData'
import Education from './screens/Education'
import WorkingExperience from './screens/WorkingExperience'
import { IFormData as IFormDataPersonalData } from './screens/PersonalData/interface'
import update from 'immutability-helper'
import CardForm from '../../components/CardForm'
import moment from 'moment'
import { useConfirmation } from '../../context/ConfirmationContext'
import { Box, Heading } from '@chakra-ui/react'
import Certificate from './screens/Certificate'
import Document from './screens/Document'
import Language from './screens/Language'
import NextOfKin from './screens/NextOfKin'
import ApplyInfo from './screens/ApplyInfo'
import AdditionalInfo from './screens/AdditionalInfo'
import Visa from './screens/Visa'

const CVForm: React.FC<FormProps> = ({ onSubmit, data, photoUrl, loading }) => {
  const [stepIndex, setStepIndex] = useState(0)

  const { confirmation, setIsLoading, close } = useConfirmation()

  const [formData, setFormData] = useState<IFormData>({
    personalData: data
      ? {
          photo: data.photo || '',
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          englishName: data.englishName || '',
          height: data.height?.toString() || '',
          weight: data.weight?.toString() || '',
          address: data.address || '',
          city: data.city || '',
          state: data.state || '',
          country: data.country || '',
          postalCode: data.postalCode || '',
          buildingName: data.buildingName || '',
          nationality: data.nationality || '',
          phone: data.phone || '',
          mobile: data.mobile || '',
          email: data.email || '',
          citizenship: data.citizenship || '',
          dateOfBirth: data.dateOfBirth
            ? moment(data.dateOfBirth).toDate()
            : null,
          placeOfBirth: data.placeOfBirth || '',
          gender: data.gender || '',
          maritalStatus: data.maritalStatus || '',
          religion: data.religion || '',
          fatherName: data.fatherName || '',
          motherName: data.motherName || '',
        }
      : null,
    additionalInfo: data
      ? {
          tattoo: data.additionalInfo?.tattoo || null,
          currentMedication: data.additionalInfo?.currentMedication || null,
          medicalProblems: data.additionalInfo?.medicalProblems || null,
          medication: data.additionalInfo?.medication || null,
          hearingProblem: data.additionalInfo?.hearingProblem || null,
          visionProblem: data.additionalInfo?.visionProblem || null,
          speechProblem: data.additionalInfo?.speechProblem || null,
          personalStrength: data.additionalInfo?.personalStrength || '',
          homeAirportCity: data.additionalInfo?.homeAirportCity || '',
          closestInternationalAirport:
            data.additionalInfo?.closestInternationalAirport || '',
          timeNeededToReachAirport:
            data.additionalInfo?.timeNeededToReachAirport || '',
          presentEmployment: data.additionalInfo?.presentEmployment || '',
          presentEmploymentSinceWhen:
            data.additionalInfo?.presentEmploymentSinceWhen || '',
          everApplied: data.additionalInfo?.everApplied || '',
          everAppliedWhen: data.additionalInfo?.everAppliedWhen || '',
          recruitmentAgency: data.additionalInfo?.recruitmentAgency || '',
        }
      : null,
    nextOfKins: (data?.nextOfKins || []).map((elm) => ({
      name: elm?.name || '',
      relation: elm?.relation || '',
      address: elm?.address || '',
      city: elm?.city || '',
      state: elm?.state || '',
      postalCode: elm?.postalCode || '',
      country: elm?.country || '',
      phone: elm?.phone || '',
      mobile: elm?.mobile || '',
      email: elm?.email || '',
      age: elm?.age || '',
      employerName: elm?.employerName || '',
    })),
    applyInfo: data
      ? {
          position: data.applyInfo?.position || '',
          department: data.applyInfo?.department || '',
          date: data.applyInfo?.date
            ? moment(data.applyInfo?.date).toDate()
            : null,
        }
      : null,
    educations: (data?.educations || []).map((elm) => ({
      educationLevel: elm?.educationLevel || '',
      schoolName: elm?.schoolName || '',
      start: elm && elm.start ? moment(elm.start).toDate() : null,
      end: elm && elm.end ? moment(elm.end).toDate() : null,
      highestStandardAttained: elm?.highestStandardAttained || '',
    })),
    certificates: data?.certificates
      ? data.certificates.map((elm) => ({
          type: elm?.type || null,
          name: elm?.name || '',
          number: elm?.number || '',
          issuePlace: elm?.issuePlace || '',
          issueDate:
            elm && elm.issueDate ? moment(elm.issueDate).toDate() : null,
          expiryDate:
            elm && elm.expiryDate ? moment(elm.expiryDate).toDate() : null,
          duration: elm?.duration || '',
        }))
      : null,
    visas: data?.visas
      ? data.visas.map((elm) => ({
          name: elm?.name || '',
          number: elm?.number || '',
          issueCity: elm?.issueCity || '',
          issueCountry: elm?.issueCountry || '',
          issueDate:
            elm && elm.issueDate ? moment(elm.issueDate).toDate() : null,
          expiryDate:
            elm && elm.expiryDate ? moment(elm.expiryDate).toDate() : null,
          //images: elm?.images || '',
        }))
      : null,
    documents: data?.documents
      ? data.documents.map((elm) => ({
          name: elm?.name || '',
          number: elm?.number || '',
          issueCity: elm?.issueCity || '',
          issueCountry: elm?.issueCountry || '',
          issueDate:
            elm && elm.issueDate ? moment(elm.issueDate).toDate() : null,
          expiryDate:
            elm && elm.expiryDate ? moment(elm.expiryDate).toDate() : null,
          //images: elm?.images || '',
        }))
      : null,
    languages: data?.languages
      ? data.languages.map((elm) => ({
          name: elm?.name || '',
          spoken: elm?.spoken || '',
          written: elm?.written || '',
          reading: elm?.reading || '',
        }))
      : null,
    workingExperiences: (data?.workingExperiences || []).map((elm) => ({
      companyName: elm?.companyName || '',
      companyLocation: elm?.companyLocation || '',
      companyType: elm?.companyType || '',
      position: elm?.position || '',
      isSeaService: elm?.isSeaService || false,
      vesselName: elm?.vesselName || '',
      vesselFlag: elm?.vesselFlag || '',
      vesselType: elm?.vesselType || '',
      vesselRank: elm?.vesselRank || '',
      vesselGrt: elm?.vesselGrt || '',
      start: elm?.start ? moment(elm.start).toDate() : null,
      end: elm?.end ? moment(elm.end).toDate() : null,
      jobDesc: elm?.jobDesc || '',
      salary: elm?.salary || '',
      remarks: elm?.remarks || '',
      leavingReason: elm?.leavingReason || '',
    })),
  })

  const back = () => {
    setStepIndex(stepIndex - 1)
  }

  const steps = [
    {
      title: 'Personal Data',
      component: (
        <PersonalData
          data={formData.personalData}
          onSubmit={(data) => {
            onSubmitForm('personalData', data, nextStep)
          }}
          photoUrl={photoUrl}
        />
      ),
    },
    {
      title: 'Additional Info',
      component: (
        <AdditionalInfo
          data={formData.additionalInfo}
          onBack={back}
          onSubmit={(data) => {
            onSubmitForm('additionalInfo', data, nextStep)
          }}
        />
      ),
    },
    {
      title: 'Next Of Kin',
      component: (
        <NextOfKin
          data={formData.nextOfKins || []}
          onBack={back}
          onNext={(data) => {
            onSubmitForm('nextOfKins', data, nextStep)
          }}
        />
      ),
    },
    {
      title: 'Position',
      component: (
        <ApplyInfo
          data={formData.applyInfo}
          onBack={back}
          onSubmit={(data) => {
            onSubmitForm('applyInfo', data, nextStep)
          }}
        />
      ),
    },
    {
      title: 'Education',
      component: (
        <Education
          data={formData.educations || []}
          onBack={back}
          onNext={(data) => {
            onSubmitForm('educations', data, nextStep)
          }}
        />
      ),
    },
    {
      title: 'Certificate',
      component: (
        <Certificate
          data={formData.certificates}
          onBack={back}
          onNext={(data) => {
            onSubmitForm('certificates', data, nextStep)
          }}
        />
      ),
    },
    {
      title: 'Visa',
      component: (
        <Visa
          data={formData.visas}
          onBack={back}
          onNext={(data) => {
            onSubmitForm('visas', data, nextStep)
          }}
        />
      ),
    },
    {
      title: 'Document',
      component: (
        <Document
          data={formData.documents}
          onBack={back}
          onNext={(data) => {
            onSubmitForm('documents', data, nextStep)
          }}
        />
      ),
    },
    {
      title: 'Language',
      component: (
        <Language
          data={formData.languages}
          onBack={back}
          onNext={(data) => {
            onSubmitForm('languages', data, nextStep)
          }}
        />
      ),
    },
    {
      title: 'Working Experience',
      component: (
        <WorkingExperience
          data={formData.workingExperiences || []}
          onBack={back}
          onNext={(data) => onSubmitForm('workingExperiences', data, nextStep)}
        />
      ),
    },
  ]

  const nextStep = stepIndex + 1

  const isLastStep = stepIndex >= steps.length - 1

  const onSubmitForm = (
    type: keyof IFormData,
    data: IFormDataPersonalData | any,
    nextStep: number
  ) => {
    const nextFormData = update(formData, {
      [type]: {
        $set: data,
      },
    })

    setFormData(nextFormData)

    if (isLastStep) {
      confirmation({
        description: 'The data will be saved to the database, continue?',
        callback: async () => {
          setIsLoading(true)
          await onSubmit(nextFormData)
          setIsLoading(false)
          close()
        },
      })
    } else {
      setStepIndex(nextStep)
    }
  }

  return (
    <>
      {/*<CardForm mb={10}>
        <CardFormTitle textAlign="left">Select Templates</CardFormTitle>
        <Templates />
  </CardForm>*/}

      <CardForm>
        {/*<Steps index={step - 1} />*/}
        {steps.map(({ title, component }, index) => {
          if (index === stepIndex) {
            return (
              <Box key={index}>
                <Heading mb={5}>
                  {index + 1}. {title}
                </Heading>
                <Box>{component}</Box>
              </Box>
            )
          }

          return <Fragment key={index} />
        })}
      </CardForm>
    </>
  )
}

export default CVForm
