import React from 'react'
import { Box, BoxProps, Image } from '@chakra-ui/react'

const Logo: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      textAlign="center"
      mb={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Image src="/logo192.png" width="120px" marginBottom='10px' />
      BOS Training Center
    </Box>
  )
}

export default Logo
