import { FieldType, ICvFormField } from '../../../../interfaces/cv'

export const fields: ICvFormField[] = [
  {
    name: 'photo',
    label: 'Photo',
    type: FieldType.IMAGE,
    alwaysShow: true,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'firstName',
    label: 'First Name',
    type: FieldType.TEXT,
    alwaysShow: true,
    required: true,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: FieldType.TEXT,
    alwaysShow: true,
    required: true,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'englishName',
    label: 'English Name',
    type: FieldType.TEXT,
    templates: ['ROYAL'],
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    type: FieldType.DATEPICKER,
    alwaysShow: true,
    required: true,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'placeOfBirth',
    label: 'Place of Birth',
    type: FieldType.TEXT,
    alwaysShow: true,
    required: true,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'gender',
    label: 'Gender',
    type: FieldType.SELECT,
    options: [
      {
        value: 'Male',
        label: 'Male',
      },
      {
        value: 'Female',
        label: 'Female',
      },
    ],
    alwaysShow: true,
    required: true,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'maritalStatus',
    label: 'Marital Status',
    type: FieldType.SELECT,
    options: [
      {
        value: 'Single',
        label: 'Single',
      },
      {
        value: 'Married',
        label: 'Married',
      },
      {
        value: 'Divorced',
        label: 'Divorced',
      },
      {
        value: 'Widowed',
        label: 'Widowed',
      },
    ],
    alwaysShow: true,
    required: true,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'height',
    label: 'Height (cm)',
    type: FieldType.NUMBER,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'ROYAL'],
  },
  {
    name: 'weight',
    label: 'Weight (kg)',
    type: FieldType.NUMBER,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'ROYAL'],
  },
  {
    name: 'address',
    label: 'Address',
    type: FieldType.TEXTAREA,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'city',
    label: 'City',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'state',
    label: 'State',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'country',
    label: 'Country',
    type: FieldType.COUNTRY,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'postalCode',
    label: 'Postal Code',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'buildingName',
    label: 'Bulding Name ',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'nationality',
    label: 'Nationality',
    type: FieldType.COUNTRY,
    templates: ['CMSI'],
  },
  {
    name: 'citizenship',
    label: 'Citizenship',
    type: FieldType.TEXT,
    templates: ['CMSI'],
  },
  {
    name: 'phone',
    label: 'Phone',
    type: FieldType.PHONE,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'mobile',
    label: 'Mobile',
    type: FieldType.PHONE,
    templates: [],
  },
  {
    name: 'email',
    label: 'Email',
    type: FieldType.EMAIL,
    required: true,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'fatherName',
    label: 'Father Name',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'motherName',
    label: 'Mother Name',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'religion',
    label: 'Religion',
    type: FieldType.TEXT,
    templates: ['CMSI'],
  },
]
