import React from 'react'
import { Heading, HeadingProps } from '@chakra-ui/react'

const PageTitle: React.FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <Heading
      as="h2"
      fontWeight="500"
      fontSize={{ base: '1.8rem', md: '2.1rem' }}
      mb="2.2rem"
      {...props}
    >
      {children}
    </Heading>
  )
}

export default PageTitle
