import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

const CardForm: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      mx="auto"
      borderRadius="16px"
      bgColor="#fff"
      px="40px"
      py="40px"
      boxShadow="0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(107, 114, 128, 0.2)"
      flex={1}
      {...props}
    >
      {children}
    </Box>
  )
}

export default CardForm
