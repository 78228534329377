import { Button, SimpleGrid } from '@chakra-ui/react'
import { useMemo } from 'react'
import { CertificateFormProps, IFormData } from './interface'
import { useForm } from 'react-hook-form'
import { FieldType, ICvFormField } from '../../interfaces/cv'
import FormFields from '../FormFields'

const CertificateForm: React.FC<CertificateFormProps> = ({
  data,
  onSubmit,
}) => {
  const form = useForm<IFormData>({
    defaultValues: data || {},
  })

  const { handleSubmit } = form

  // const filteredFields = fields.filter((elm) => {
  //   return (
  //     elm.alwaysShow || templates.some((elm2) => elm.templates?.includes(elm2))
  //   )
  // })

  const fields: ICvFormField[] = useMemo(() => {
    return [
      {
        name: 'name',
        label: 'Name',
        type: FieldType.TEXTAREA,
        required: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI'],
        readOnly: data?.isDefault,
      },
      {
        name: 'type',
        label: 'Type',
        type: FieldType.SELECT,
        options: [
          {
            value: 'COMPETENCY',
            label: 'Competency',
          },
          {
            value: 'PROFICIENCY',
            label: 'Proficiency',
          },
        ],
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI'],
        readOnly: data?.isDefault,
      },
      {
        name: 'number',
        label: 'Number',
        type: FieldType.TEXT,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'issuePlace',
        label: 'Issue Place',
        type: FieldType.TEXT,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'issueDate',
        label: 'Issue Date',
        type: FieldType.DATEPICKER,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'expiryDate',
        label: 'Expiry Date',
        type: FieldType.DATEPICKER,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'duration',
        label: 'Duration',
        type: FieldType.TEXT,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
    ]
  }, [data])

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <SimpleGrid columns={2} spacing={10}>
        <FormFields fields={fields} hookForm={form} />
      </SimpleGrid>

      <Button type="submit">Submit</Button>
    </form>
  )
}

export default CertificateForm
