import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from '../Modal'
import { Image } from '@chakra-ui/react'

type IModalImage = {
  src: string
  onClose?: () => void
} & ModalProps

const ModalPopupImage: React.FC<IModalImage> = ({ src, isOpen, onClose }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    function updateWindowSize() {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', updateWindowSize)

    return () => {
      window.removeEventListener('resize', updateWindowSize)
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton={true}>
      <Image
        w="auto"
        maxWidth="100%"
        h="auto"
        alt="Preview"
        src={src}
        style={{ maxHeight: windowHeight }}
        maxHeight={windowHeight}
      />
    </Modal>
  )
}

export default ModalPopupImage
