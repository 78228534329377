import { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { ListIcon, MenuIcon, QuitIcon, UserIcon } from '../../components/Icon'
import RouterLink from '../../components/RouterLink'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'
import { useConfirmation } from '../../context/ConfirmationContext'
import { IMenu } from '../../interfaces/menu'
import { useAuth } from '../../context/AuthContext'
import { UserLevel, useLogoutMutation } from '../../generated/graphql'

const Nav = styled.div`
  flex: 1;
`

const NavItem = styled.div`
  padding-top: 3px;

  width: 100%;

  a,
  button {
    color: #131415;
    text-decoration: none;
    padding: 8px 10px;
    flex-grow: 1;
    border-radius: 5px;
    font-size: 0.97rem;
    line-height: 1.5;
    display: flex;
    align-items: center;
    font-weight: 400;
    width: 100%;
    border: none;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      background: #e1e2e6;
      text-decoration: none;
    }

    &.active {
      background: #fff;
      color: #131415;
      font-weight: 500;
    }
  }

  span {
    margin-left: 10px;
  }

  svg {
    width: 16px;
    height: auto;
  }
`

const LogoContainer = styled.div`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1rem;

    &:hover {
      text-decoration: none;
    }
  }
  img {
    width: 18px;
    margin-right: 10px;
  }
`

const MenuDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { me, setMe, setIsLogin } = useAuth()
  const btnRef = useRef<HTMLButtonElement>(null)
  const { confirmation, setIsLoading, close } = useConfirmation()

  let menus: IMenu[] = []

  const shopMenus = [
    {
      label: 'Main Menu',
    },
    {
      link: '/cv',
      icon: <ListIcon />,
      label: 'List CVs',
    },
  ]

  const accountMenus = [
    {
      label: 'Administration',
    },
    {
      link: '/me',
      icon: <UserIcon />,
      label: 'Akun',
    },
  ]

  if (me?.level === UserLevel.Superadmin) {
    shopMenus.push({
      link: '/user',
      icon: <ListIcon />,
      label: 'List Users',
    })
  }

  menus = shopMenus.concat(accountMenus)

  const [logoutMutation] = useLogoutMutation()

  const logout = async () => {
    setIsLoading(true)
    await logoutMutation()
    setIsLoading(false)
    setMe(null)
    setIsLogin(false)
    close()
  }

  return (
    <>
      <Box bgColor="#fff" px="30px" py={3}>
        <Flex alignItems="center" gap={3}>
          <Button ref={btnRef} onClick={onOpen} variant="link">
            <MenuIcon />
          </Button>
        </Flex>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <LogoContainer>
              <RouterLink to="/">
                <Box as="span">BOS Training Center</Box>
              </RouterLink>
            </LogoContainer>
          </DrawerHeader>

          <DrawerBody>
            <Nav>
              {menus.map((menu, index) => {
                if (menu.link) {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        to={menu.link}
                        onClick={() => onClose()}
                        // className={({ isActive, isPending }) =>
                        //   isPending ? "pending" : isActive ? "active" : ""
                        // }
                      >
                        {menu.icon} <span>{menu.label}</span>
                      </NavLink>
                    </NavItem>
                  )
                }

                return (
                  <Box
                    textTransform="uppercase"
                    fontWeight={500}
                    fontSize="0.8rem"
                    color="#616266"
                    padding="8px 10px"
                    lineHeight={1.5}
                    mt="0.5rem"
                    key={index}
                  >
                    {menu.label}
                  </Box>
                )
              })}
            </Nav>
          </DrawerBody>

          <DrawerFooter>
            <Box borderTop="1px solid #dedede" w="full">
              <NavItem>
                <button
                  onClick={async () => {
                    confirmation({
                      description: 'Are you sure want to logout?',
                      callback: logout,
                    })
                  }}
                >
                  <QuitIcon />
                  <span>Logout</span>
                </button>
              </NavItem>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MenuDrawer
