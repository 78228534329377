import React, { ReactElement } from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

export interface ModalProps {
  isOpen: boolean
  onClose: () => void
  headerText?: string
  footer?: string
  withCloseButton?: boolean
  children?: ReactElement
}

const ModalComp: React.FC<ModalProps> = ({
  headerText,
  footer,
  isOpen,
  onClose,
  withCloseButton,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="none" w={{ base: '90%', md: '80%', lg: '70%' }}>
        {headerText && <ModalHeader>{headerText}</ModalHeader>}
        {withCloseButton && <ModalCloseButton />}
        <ModalBody py={30}>{children}</ModalBody>

        {footer && (
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="clean">Secondary Action</Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ModalComp
