import React, { useContext, useState } from 'react'
import { FormProviderProps, IFormContext } from './interface'
import { useListAllCountriesQuery } from '../../generated/graphql'

const FormContext = React.createContext<IFormContext>({
  templates: [],
  setTemplates: () => {},
})

const FormCvProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [templates, setTemplates] = useState<any[]>([])

  const { data: countries, loading: loadingCountries } =
    useListAllCountriesQuery()

  return (
    <FormContext.Provider
      value={{ templates, setTemplates, countries, loadingCountries }}
    >
      {children}
    </FormContext.Provider>
  )
}

export default FormContext

const useFormCv = () => useContext(FormContext)

export { FormCvProvider, useFormCv }
