import { Box } from '@chakra-ui/layout'
import { Flex } from '@chakra-ui/react'
import ArrowPagination from '../ArrowPagination'
import React from 'react'
import { TableListPaginationProps } from './interface'

const TableListPagination: React.FC<TableListPaginationProps> = ({
  filterComponents,
  paginationProps,
}) => {
  return (
    <Box borderBottom="1px solid #dedede">
      <Box maxW="none" py="5" px="5">
        <Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Box flex={1}>{filterComponents}</Box>
          <Box flex={1} textAlign="right" display="flex" justifyContent="flex-end">
            <ArrowPagination {...paginationProps} />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default TableListPagination
