import React from 'react'
import { Navigate, Outlet, RouteProps } from 'react-router-dom'
import Authenticated from '../layouts/Authenticated'

type IProtectedRouteProps = RouteProps & { isLoggedIn: any }
const ProtectedRouteUser: React.FC<IProtectedRouteProps> = ({ isLoggedIn }) => {
  if (!isLoggedIn) {
    return <Navigate to="/signin" />
  }

  return (
    <Authenticated>
      <Outlet />
    </Authenticated>
  )
}

export default ProtectedRouteUser
