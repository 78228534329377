import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { FormProps, ICertificateItem } from './interface'
import ActionButtons from '../../ActionButtons'
import TableList from '../../../../components/TableList'
import ModalCertificateForm from '../../../../components/ModalCertificateForm'
import TableListTitle from '../../../../components/TableList/TableListTitle'
import { useConfirmation } from '../../../../context/ConfirmationContext'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import { IFormData } from '../../../../components/ModalCertificateForm/interface'
import moment from 'moment'
import { CertificateType } from '../../../../generated/graphql'

const defaultCertificates = [
  {
    name: 'Basic Safety Training',
    type: CertificateType.Proficiency,
  },
  {
    name: 'Crowd Management',
    type: CertificateType.Proficiency,
  },
  {
    name: 'Crisis Management',
    type: CertificateType.Proficiency,
  },
  {
    name: 'Survival Craft Rescue Boat',
    type: CertificateType.Proficiency,
  },
  {
    name: 'Security Awareness',
    type: CertificateType.Proficiency,
  },
  {
    name: 'Section A-V/2 (par. 1,2,3,4,5) (state which paragraphs)\nSection A-V/3 (par. 1,2,3,4,5) (state which paragraphs)',
    type: null,
  },
  {
    name: 'Crowd Management\nCrisis Management',
    type: null,
  },
]

const Certificate: React.FC<FormProps> = ({ onBack, onNext, data }) => {
  const {
    isOpen: isOpenForm,
    onOpen: onOpenForm,
    onClose: onCloseForm,
  } = useDisclosure()

  const [certificates, setCertificates] = useState<ICertificateItem[]>(
    data
      ? data.map((elm) => {
          return {
            rowItemId: uuidv4(),
            data: {
              ...elm,
              isDefault: defaultCertificates
                .map(({ name }) => name)
                .includes(elm.name),
            },
          }
        })
      : defaultCertificates.map(({ name, type }) => ({
          rowItemId: uuidv4(),
          data: {
            name,
            type,
            number: '',
            issuePlace: '',
            issueDate: null,
            expiryDate: null,
            isDefault: true,
          },
        }))
  )

  const { confirmation, close } = useConfirmation()

  const [selectedIndex, setSeletedIndex] = useState<number>(-1)
  const [selectedItem, setSelectedItem] = useState<ICertificateItem | null>(
    null
  )

  const onLocalSubmit = (itemData: IFormData) => {
    let nextCertificates = null
    if (selectedItem !== null) {
      nextCertificates = update(certificates, {
        [selectedIndex]: {
          data: { $set: itemData },
        },
      })
    } else {
      nextCertificates = update(certificates, {
        $push: [{ rowItemId: uuidv4(), data: itemData }],
      })
    }

    setCertificates(nextCertificates)
    onCloseForm()
    setSelectedItem(null)
  }

  const deleteItem = (index: number) => {
    const nextCertificates = update(certificates, {
      $splice: [[index, 1]],
    })

    setCertificates(nextCertificates)
  }

  return (
    <>
      <TableListTitle
        title="Certificate List"
        actionComponents={
          <Button size="sm" onClick={onOpenForm}>
            Add Certificate
          </Button>
        }
      />
      <TableList
        resources={certificates}
        dataArrayRender={(certificate) => {
          return [
            <div
              dangerouslySetInnerHTML={{
                __html: certificate.data.name.replace(/\n/g, '<br />'),
              }}
            />,
            certificate.data.type || '',
            certificate.data.number,
            certificate.data.issuePlace,
            certificate.data.issueDate
              ? moment(certificate.data.issueDate).format('YYYY-MM-DD')
              : '',
            certificate.data.expiryDate
              ? moment(certificate.data.expiryDate).format('YYYY-MM-DD')
              : '',
          ]
        }}
        headings={[
          'Name',
          'Type',
          'Number',
          'Issue Place',
          'Issue Date',
          'Expiry Date',
          '',
        ]}
        actions={(certificate, index) => (
          <Flex gap={4}>
            <Button
              onClick={() => {
                setSeletedIndex(index)
                setSelectedItem(certificate)
                onOpenForm()
              }}
              size="sm"
            >
              Edit
            </Button>
            {!certificate.data.isDefault && (
              <Button
                variant="link"
                onClick={() => {
                  confirmation({
                    description: 'Are you sure want to delete the data?',
                    callback: () => {
                      deleteItem(index)
                      close()
                    },
                  })
                }}
              >
                Delete
              </Button>
            )}
          </Flex>
        )}
      />
      <ActionButtons
        mt={10}
        onBack={onBack}
        onNext={() => onNext(certificates.map((elm) => elm.data))}
      />
      <ModalCertificateForm
        data={selectedItem?.data}
        isOpen={isOpenForm}
        onClose={() => {
          onCloseForm()
          setSelectedItem(null)
        }}
        onSubmit={onLocalSubmit}
      />
    </>
  )
}

export default Certificate
