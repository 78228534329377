export const templates = [
  {
    value: 'LAUTAN_CAHAYA_PERMATA',
    label: 'Lautan Cahaya Permata (PDF)',
    path: 'lautan-cahaya-permata',
  },
  {
    value: 'CMSI',
    label: 'CMSI (PDF)',
    path: 'cmsi',
  },
  {
    value: 'ROYAL',
    label: 'Royal (PDF)',
    path: 'royal',
  },
  {
    value: 'PERSONAL_INFO_FORM',
    label: 'Personal Info Form (PDF)',
    path: 'personal-info-form',
  },
  {
    value: 'PERSONAL_INFORMATION_NOK',
    label: 'Personal Information + NOK (EXCEL)',
    path: 'personal-information-nok',
  },
  {
    value: 'FC7',
    label: 'FC 7 (PDF)',
    path: 'fc7',
  },
  {
    value: 'FORM_CV',
    label: 'Form CV (PDF)',
    path: 'form-cv',
  },
]
