const Alert = {
  parts: ["container"],
  baseStyle: {
    container: {
      borderRadius: "8px",
    },
  },
};

export default Alert;
