import { IFormData } from './interface'
import PageTitle from '../../components/PageTitle'
import UserForm from './Form'
import { useFormAdd } from '../../hooks/formAdd'
import { useNavigate } from 'react-router-dom'
import { useCreateUserMutation } from '../../generated/graphql'
import { useToast } from '@chakra-ui/react'

const UserAdd: React.FC = () => {
  const navigate = useNavigate()

  const toast = useToast()

  const [createUser] = useCreateUserMutation()

  const submitCallback = async (formData: IFormData) => {
    const { name, email, password, level } = formData

    try {
      await createUser({
        variables: {
          input: {
            name,
            email,
            password,
            level,
          },
        },
      })

      toast({
        description: 'User has been created successfully!',
        status: 'success',
      })
    } catch (err: any) {
      toast({
        status: 'error',
        description: 'Email is already exists!',
      })
    }
  }

  const { onSubmit, isLoadingSubmit } = useFormAdd<IFormData>({
    submitCallback,
    onSubmitSucess() {
      navigate('/user')
    },
  })

  return (
    <>
      <PageTitle>Create User</PageTitle>
      <UserForm onSubmit={onSubmit} loading={isLoadingSubmit} />
    </>
  )
}

export default UserAdd
