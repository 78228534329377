import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme/theme'
import GlobalStyles from './theme/GlobalStyles'
import { AuthProvider } from './context/AuthContext'
import { AppRoutes } from './AppRoutes'
import { ApolloProvider } from '@apollo/client'
import { noCacheClient } from './helpers/apolloClient'
import { AlertProvider } from './context/AlertContext'
import { ConfirmationProvider } from './context/ConfirmationContext'
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <ApolloProvider client={noCacheClient}>
      <ChakraProvider theme={theme}>
        <GlobalStyles />
        <ConfirmationProvider>
          <AlertProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </AlertProvider>
        </ConfirmationProvider>
      </ChakraProvider>
    </ApolloProvider>
  )
}

export default App
