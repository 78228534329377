import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Box,
  Select,
  Switch,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'
import TextRequired from '../TextRequired'
import ReactSelect from 'react-select'
import { FormFieldsProps } from './inteface'
import { FieldType } from '../../interfaces/cv'
import { useFormCv } from '../../pages/CV/FormContext'
import FormImage from '../FormImage'
import FormBooleanText from '../FormBooleanText'

const FormFields: React.FC<FormFieldsProps> = ({ fields, hookForm }) => {
  const { loadingCountries, countries } = useFormCv()

  const {
    register,
    formState: { errors },
    control,
  } = hookForm

  return (
    <>
      {fields.map((field) => (
        <FormControl
          key={field.name}
          isInvalid={errors.hasOwnProperty(field.name)}
        >
          <FormLabel htmlFor={field.name}>
            {field.label}
            {field.required && <TextRequired>*</TextRequired>}
          </FormLabel>

          {field.type === FieldType.BOOLEANTEXT ? (
            <Controller
              name={field.name}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormBooleanText
                    value={value}
                    onChange={onChange}
                    placeholder={field.placeholder}
                  />
                )
              }}
            />
          ) : field.type === FieldType.IMAGE ? (
            <Controller
              name={field.name}
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <FormImage onChange={onChange} preview={field.imageUrl} />
                )
              }}
            />
          ) : field.type === FieldType.BOOLEAN ? (
            <Switch id={field.name} {...register(field.name)} />
          ) : field.type === FieldType.PHONE ? (
            <Input
              key={field.name}
              id={field.name}
              {...register(field.name, {
                required: field.required || false,
              })}
            />
          ) : field.type === FieldType.COUNTRY ? (
            <Box key={field.name}>
              {loadingCountries ? (
                <Box>Loading...</Box>
              ) : (
                <Controller
                  name={field.name}
                  rules={{ required: field.required }}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    const options = (countries?.listAllCountries || []).map(
                      (country) => {
                        return {
                          value: country?.countryName,
                          label: country?.countryName,
                        }
                      }
                    )

                    return (
                      <ReactSelect
                        onChange={(e) => onChange(e?.label)}
                        isClearable
                        options={options}
                        value={options.find((elm) => elm?.label === value)}
                      />
                    )
                  }}
                />
              )}
            </Box>
          ) : field.type === FieldType.SELECT ? (
            <Select
              key={field.name}
              id={field.name}
              {...register(field.name, {
                required: field.required,
              })}
              disabled={field.readOnly}
            >
              <option value="">-- Select {field.label} --</option>
              {(field.options || []).map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ) : field.type === FieldType.DATEPICKER ? (
            <Controller
              key={field.name}
              control={control}
              name={field.name}
              rules={{ required: field.required }}
              render={({ field: { onChange, value } }) => (
                <Input
                  as={DatePicker}
                  selected={value as Date | null | undefined}
                  onChange={onChange}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                />
              )}
            />
          ) : field.type === FieldType.EMAIL ? (
            <Input
              key={field.name}
              id={field.name}
              {...register(field.name, {
                required: field.required,
              })}
            />
          ) : field.type === FieldType.TEXTAREA ? (
            <Textarea
              key={field.name}
              id={field.name}
              {...register(field.name, {
                required: field.required,
              })}
              disabled={field.readOnly}
            ></Textarea>
          ) : (
            <Input
              key={field.name}
              id={field.name}
              {...register(field.name, {
                required: field.required,
              })}
              disabled={field.readOnly}
              type={field.type === FieldType.NUMBER ? 'number' : 'text'}
            />
          )}
          <FormErrorMessage>{field.label} is required!</FormErrorMessage>
        </FormControl>
      ))}
    </>
  )
}

export default FormFields
