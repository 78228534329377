import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { InputGroup, Input } from '@chakra-ui/react'
import { IProductFilter } from './interface'

const ProductFilter: React.FC<IProductFilter> = ({ onSubmit, value }) => {
  const { register, handleSubmit, setValue } = useForm()

  useEffect(() => {
    let key: string | undefined = undefined

    if (value) {
      const { key: _key } = value

      key = _key
    }

    setValue('key', key)
  }, [value, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputGroup>
        <Input
          id="key"
          placeholder="Search Applicant by First Name, Last Name or Email"
          {...register('key')}
          size="sm"
        />
      </InputGroup>
    </form>
  )
}

export default ProductFilter
