import { FormBooleanTextProps } from './inteface'
import { useEffect, useState } from 'react'
import { Box, Flex, Input, Switch } from '@chakra-ui/react'

const FormBooleanText: React.FC<FormBooleanTextProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  const [isChecked, setIsChecked] = useState(false)
  const [text, setText] = useState('')

  useEffect(() => {
    if (value !== null && value !== undefined) {
      setIsChecked(true)
      setText(value)
    }
  }, [value])

  const onLocalChange = (
    fieldType: 'check' | 'text',
    value: string | boolean
  ) => {
    if (fieldType === 'check') {
      setIsChecked(value as boolean)
    } else if (fieldType === 'text') {
      setText(value as string)
    }

    if (fieldType === 'check' && (value as boolean)) {
      onChange(text)
    } else if (fieldType === 'text') {
      onChange(value as string)
    } else {
      onChange(null)
    }
  }

  return (
    <Flex alignItems="center" gap={5}>
      <Switch
        isChecked={isChecked}
        onChange={(e) => {
          onLocalChange('check', e.target.checked)
        }}
      />
      <Box h="50px" flex={1}>
        {isChecked && (
          <Input
            placeholder={placeholder}
            value={text}
            onChange={(e) => {
              onLocalChange('text', e.target.value)
            }}
          />
        )}
      </Box>
    </Flex>
  )
}

export default FormBooleanText
