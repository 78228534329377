import { Tbody, Td, Tr, Skeleton } from "@chakra-ui/react";
import React, { memo } from "react";

interface ITBodyPlaceholder {
  rowNumber: number;
  colNumber: number;
  randomWidth?: number[][];
}

const TBodyPlaceholder: React.FC<ITBodyPlaceholder> = ({
  rowNumber,
  colNumber,
  randomWidth,
}) => {
  const trs = [];
  for (let i = 0; i < rowNumber; i++) {
    const tds = [];
    for (let n = 0; n < colNumber; n++) {
      tds.push(
        <Td key={`td-${i}-${n}`}>
          <Skeleton
            w={
              randomWidth &&
              randomWidth[n] &&
              randomWidth[n][Math.floor(Math.random() * randomWidth[n].length)]
            }
            h="16px"
          />
        </Td>
      );
    }
    trs.push(<Tr key={`tr-${i}`}>{tds}</Tr>);
  }

  return <Tbody>{trs}</Tbody>;
};

export default memo(TBodyPlaceholder, (prevProps, props) => {
  return true;
});
