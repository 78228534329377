import { useState } from 'react'

interface IUseFormAdd<D> {
  submitCallback: (formData: D) => Promise<any>
  onSubmitSucess?: () => void
}

export function useFormAdd<D>({
  submitCallback,
  onSubmitSucess,
}: IUseFormAdd<D>) {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const onSubmit = async (formData: D) => {
    setIsLoadingSubmit(true)

    try {
      await submitCallback(formData)

      if (onSubmitSucess) onSubmitSucess()
    } catch (err) {
      console.log('__err', err)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  return { isLoadingSubmit, onSubmit }
}
