import { IFormData } from './interface'
import PageTitle from '../../components/PageTitle'
import CVForm from './Form'
import { useFormAdd } from '../../hooks/formAdd'
import { useNavigate } from 'react-router-dom'
import { useCreateCvMutation } from '../../generated/graphql'
import moment from 'moment'
import { IFormData as IFormDataPersonal } from './screens/PersonalData/interface'
import { FormCvProvider, useFormCv } from './FormContext'
import { useToast } from '@chakra-ui/react'

const CvAdd: React.FC = () => {
  const navigate = useNavigate()

  const { templates } = useFormCv()

  const toast = useToast()

  const [createCV] = useCreateCvMutation()

  const submitCallback = async (formData: IFormData) => {
    const {
      personalData,
      additionalInfo,
      nextOfKins,
      applyInfo,
      educations,
      certificates,
      visas,
      documents,
      languages,
      workingExperiences,
    } = formData

    const inputPersonalData = {
      ...(personalData as IFormDataPersonal),
      dateOfBirth: moment(personalData?.dateOfBirth).format('YYYY-MM-DD'),
      height: parseInt((personalData?.height || '').toString()),
      weight: parseInt((personalData?.weight || '').toString()),
    }

    const applyInfoInput = {
      ...applyInfo,
      date: applyInfo?.date ? moment(applyInfo.date).format('YYYY-MM-DD') : '',
    }

    const inputTemplates = templates.map((elm) => elm)

    const inputEducations = educations?.map((elm) => ({
      ...elm,
      start: elm.start ? moment(elm.start).format('YYYY-MM-DD') : '',
      end: elm.end ? moment(elm.end).format('YYYY-MM-DD') : '',
    }))

    const inputCertificates = certificates?.map(({ isDefault, ...elm }) => ({
      ...elm,
      issueDate: elm.issueDate
        ? moment(elm.issueDate).format('YYYY-MM-DD')
        : '',
      expiryDate: elm.expiryDate
        ? moment(elm.expiryDate).format('YYYY-MM-DD')
        : '',
    }))

    const inputVisas = visas?.map(({ isDefault, ...elm }) => ({
      ...elm,
      issueDate: elm.issueDate
        ? moment(elm.issueDate).format('YYYY-MM-DD')
        : '',
      expiryDate: elm.expiryDate
        ? moment(elm.expiryDate).format('YYYY-MM-DD')
        : '',
    }))

    const inputDocuments = documents?.map(({ isDefault, ...elm }) => ({
      ...elm,
      issueDate: elm.issueDate
        ? moment(elm.issueDate).format('YYYY-MM-DD')
        : '',
      expiryDate: elm.expiryDate
        ? moment(elm.expiryDate).format('YYYY-MM-DD')
        : '',
    }))

    const inputLanguages = languages?.map(({ isDefault, ...elm }) => ({
      ...elm,
    }))

    const inputWorkingExperiences = workingExperiences?.map(({ ...elm }) => ({
      ...elm,
      start: moment(elm.start).format('YYYY-MM-DD'),
      end: moment(elm.end).format('YYYY-MM-DD'),
    }))

    try {
      await createCV({
        variables: {
          input: {
            ...inputPersonalData,
            applyInfo: applyInfoInput,
            nextOfKins,
            additionalInfo,
            templates: inputTemplates,
            educations: inputEducations,
            certificates: inputCertificates,
            visas: inputVisas,
            documents: inputDocuments,
            languages: inputLanguages,
            workingExperiences: inputWorkingExperiences,
          },
        },
      })

      toast({
        description: 'CV has been created successfully!',
        status: 'success',
      })
    } catch (err) {
      console.log('Create CV Error: ', err)

      toast({
        description: 'Error',
        status: 'error',
      })
    }
  }

  const { onSubmit, isLoadingSubmit } = useFormAdd<IFormData>({
    submitCallback,
    onSubmitSucess() {
      navigate('/cv')
    },
  })

  return (
    <FormCvProvider>
      <PageTitle>Create CV</PageTitle>
      <CVForm onSubmit={onSubmit} loading={isLoadingSubmit} />
    </FormCvProvider>
  )
}

export default CvAdd
