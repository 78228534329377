import React from 'react'
import { ComponentWithAs, Icon, IconProps } from '@chakra-ui/react'

const IconComp: ComponentWithAs<'svg', IconProps> = ({
  children,
  ...props
}) => {
  return (
    <Icon viewBox="0 0 512 512" boxSize="20px" {...props}>
      <path
        d="M56.424,146.286c-28.277,0-51.2,22.923-51.2,51.2s22.923,51.2,51.2,51.2s51.2-22.923,51.2-51.2
				S84.701,146.286,56.424,146.286z M56.424,227.788L56.424,227.788c-16.735,0-30.302-13.567-30.302-30.302
				s13.567-30.302,30.302-30.302c16.735,0,30.302,13.567,30.302,30.302S73.16,227.788,56.424,227.788z"
      />
      <path
        d="M379.298,187.037H143.151c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449h236.147
				c5.771,0,10.449-4.678,10.449-10.449S385.069,187.037,379.298,187.037z"
      />
      <path
        d="M56.424,0c-28.277,0-51.2,22.923-51.2,51.2s22.923,51.2,51.2,51.2s51.2-22.923,51.2-51.2S84.701,0,56.424,0z
				 M56.424,81.502c-16.735,0-30.302-13.567-30.302-30.302s13.567-30.302,30.302-30.302S86.726,34.465,86.726,51.2
				S73.16,81.502,56.424,81.502z"
      />
      <path
        d="M143.151,61.649h236.147c5.771,0,10.449-4.678,10.449-10.449s-4.678-10.449-10.449-10.449H143.151
				c-5.771,0-10.449,4.678-10.449,10.449S137.38,61.649,143.151,61.649z"
      />
      <path
        d="M56.424,292.571c-28.277,0-51.2,22.923-51.2,51.2c0,28.277,22.923,51.2,51.2,51.2s51.2-22.923,51.2-51.2
				C107.624,315.494,84.701,292.571,56.424,292.571z M86.726,343.771c0,16.735-13.567,30.302-30.302,30.302v0
				c-16.735,0-30.302-13.567-30.302-30.302c0-16.735,13.567-30.302,30.302-30.302S86.726,327.036,86.726,343.771L86.726,343.771z"
      />
      <path
        d="M379.298,333.322H143.151c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449h236.147
				c5.771,0,10.449-4.678,10.449-10.449S385.069,333.322,379.298,333.322z"
      />
    </Icon>
  )
}

export default IconComp
