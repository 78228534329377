import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Logo from '../../components/Logo'
import EmptyFullHeight from '../../layouts/EmptyFullHeight'
import CardForm from '../../components/CardForm'
import CardFormTitle from '../../components/CardForm/CardFormTitle'
import { IFormData } from './interface'
import { useAuth } from '../../context/AuthContext'
import { User, useLoginMutation } from '../../generated/graphql'

const Login = () => {
  const navigate = useNavigate()
  const { setMe, setIsLogin } = useAuth()

  const toast = useToast()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>()

  const [login, { loading }] = useLoginMutation()

  const onSubmit = async (formData: IFormData) => {
    const { email, password } = formData

    try {
      const { data } = await login({
        variables: { input: { email, password } },
      })

      setMe(data?.login as User)
      setIsLogin(true)
      navigate('/')
    } catch (err: any) {
      toast({
        description:
          err.message === 'invalid_credentials' ? 'User not found!' : 'Error',
        status: 'error',
      })
    }
  }

  return (
    <EmptyFullHeight bgColor="#f3f4f6">
      <CardForm maxW="420px">
        <Logo />
        <CardFormTitle>Login</CardFormTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.hasOwnProperty('email')}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              className="form-control"
              id="email"
              placeholder="Enter your email"
              {...register('email', {
                required: true,
              })}
            />
            {errors?.email?.type === 'pattern' ? (
              <FormErrorMessage className="d-block">
                Wrong format!
              </FormErrorMessage>
            ) : errors?.email?.type === 'required' ? (
              <FormErrorMessage>Email is required!</FormErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>

          <FormControl isInvalid={errors.hasOwnProperty('password')}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              type="password"
              id="password"
              placeholder="Enter your password"
              {...register('password', { required: true, minLength: 8 })}
            />
            {errors?.password?.type === 'minLength' ? (
              <FormErrorMessage className="d-block">
                Min 8 chars!
              </FormErrorMessage>
            ) : errors?.password?.type === 'required' ? (
              <FormErrorMessage>Password is required!</FormErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>

          <Button type="submit" w="100%" isLoading={loading}>
            Login
          </Button>
        </form>
      </CardForm>
    </EmptyFullHeight>
  )
}

export default Login
