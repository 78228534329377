import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { FormProps, IDocumentItem } from './interface'
import ActionButtons from '../../ActionButtons'
import TableList from '../../../../components/TableList'
import ModalDocumentForm from '../../../../components/ModalDocumentForm'
import TableListTitle from '../../../../components/TableList/TableListTitle'
import { useConfirmation } from '../../../../context/ConfirmationContext'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import { IFormData } from '../../../../components/ModalDocumentForm/interface'
import moment from 'moment'

const defaultDocuments = ['Passport', 'Seaman Book', 'Yellow Fever']

const Document: React.FC<FormProps> = ({ onBack, onNext, data = [] }) => {
  const {
    isOpen: isOpenForm,
    onOpen: onOpenForm,
    onClose: onCloseForm,
  } = useDisclosure()

  const [documents, setDocuments] = useState<IDocumentItem[]>(
    data
      ? data.map((elm) => {
          return {
            rowItemId: uuidv4(),
            data: { ...elm, isDefault: defaultDocuments.includes(elm.name) },
          }
        })
      : defaultDocuments.map((elm) => ({
          rowItemId: uuidv4(),
          data: {
            name: elm,
            number: '',
            issueCity: '',
            issueCountry: '',
            issueDate: null,
            expiryDate: null,
            images: [],
            isDefault: true,
          },
        }))
  )

  const { confirmation, close } = useConfirmation()

  const [selectedIndex, setSeletedIndex] = useState<number>(-1)
  const [selectedItem, setSelectedItem] = useState<IDocumentItem | null>(null)

  const onLocalSubmit = (itemData: IFormData) => {
    let nextDocuments = null
    if (selectedItem !== null) {
      nextDocuments = update(documents, {
        [selectedIndex]: {
          data: { $set: itemData },
        },
      })
    } else {
      nextDocuments = update(documents, {
        $push: [{ rowItemId: uuidv4(), data: itemData }],
      })
    }

    setDocuments(nextDocuments)
    onCloseForm()
    setSelectedItem(null)
  }

  const deleteItem = (index: number) => {
    const nextDocuments = update(documents, {
      $splice: [[index, 1]],
    })

    setDocuments(nextDocuments)
  }

  return (
    <>
      <TableListTitle
        title="Document List"
        actionComponents={
          <Button size="sm" onClick={onOpenForm}>
            Add Document
          </Button>
        }
      />
      <TableList
        resources={documents}
        dataArrayRender={(document) => {
          return [
            document.data.name,
            document.data.number,
            document.data.issueCity,
            document.data.issueCountry,
            document.data.issueDate
              ? moment(document.data.issueDate).format('YYYY-MM-DD')
              : '',
            document.data.expiryDate
              ? moment(document.data.expiryDate).format('YYYY-MM-DD')
              : '',
          ]
        }}
        headings={[
          'Name',
          'Number',
          'Issue City',
          'Issue Country',
          'Issue Date',
          'Expiry Date',
          '',
        ]}
        actions={(document, index) => (
          <Flex gap={4}>
            <Button
              onClick={() => {
                setSeletedIndex(index)
                setSelectedItem(document)
                onOpenForm()
              }}
              size="sm"
            >
              Edit
            </Button>
            {!document.data.isDefault && (
              <Button
                variant="link"
                onClick={() => {
                  confirmation({
                    description: 'Are you sure want to delete the data?',
                    callback: () => {
                      deleteItem(index)
                      close()
                    },
                  })
                }}
              >
                Delete
              </Button>
            )}
          </Flex>
        )}
      />
      <ActionButtons
        mt={10}
        onBack={onBack}
        onNext={() => onNext(documents.map((elm) => elm.data))}
      />
      <ModalDocumentForm
        data={selectedItem?.data}
        isOpen={isOpenForm}
        onClose={() => {
          onCloseForm()
          setSelectedItem(null)
        }}
        onSubmit={onLocalSubmit}
      />
    </>
  )
}

export default Document
