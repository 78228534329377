import { Box, BoxProps, Flex } from '@chakra-ui/react'
import MenuDrawer from './MenuDrawer'

const Authenticated = ({ children }: BoxProps) => {
  return (
    <Box h="100%" bg="#f3f4f6">
      <MenuDrawer />
      <Box>
        <Box
          flex={1}
          maxW="none"
          pt="40px"
          pb="60px"
          overflowY="auto"
          px={{ md: '30px' }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Authenticated
