import { Button, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { FormProps, IWorkingExperienceItem } from './interface'
import ActionButtons from '../../ActionButtons'
import ModalWorkingExperienceForm from '../../../../components/ModalWorkingExperienceForm'
import { useConfirmation } from '../../../../context/ConfirmationContext'
import { IFormData } from '../../../../components/ModalWorkingExperienceForm/interface'
import moment from 'moment'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import TableListTitle from '../../../../components/TableList/TableListTitle'
import TableList from '../../../../components/TableList'

const WorkingExperience: React.FC<FormProps> = ({ onBack, onNext, data }) => {
  const {
    isOpen: isOpenForm,
    onOpen: onOpenForm,
    onClose: onCloseForm,
  } = useDisclosure()

  const [workingExperiences, setWorkingExperiences] = useState<
    IWorkingExperienceItem[]
  >(
    data
      ? data.map((elm) => {
          return {
            rowItemId: uuidv4(),
            data: elm,
          }
        })
      : []
  )

  const { confirmation, close } = useConfirmation()

  const [selectedIndex, setSeletedIndex] = useState<number>(-1)
  const [selectedItem, setSelectedItem] =
    useState<IWorkingExperienceItem | null>(null)

  const onLocalSubmit = (itemData: IFormData) => {
    let nextWorkingExperiences = null
    if (selectedItem !== null) {
      nextWorkingExperiences = update(workingExperiences, {
        [selectedIndex]: {
          data: { $set: itemData },
        },
      })
    } else {
      nextWorkingExperiences = update(workingExperiences, {
        $push: [{ rowItemId: uuidv4(), data: itemData }],
      })
    }

    setWorkingExperiences(nextWorkingExperiences)
    onCloseForm()
    setSelectedItem(null)
  }

  const deleteItem = (index: number) => {
    const nextWorkingExperiences = update(workingExperiences, {
      $splice: [[index, 1]],
    })

    setWorkingExperiences(nextWorkingExperiences)
  }

  return (
    <>
      <TableListTitle
        title="Working Experience List"
        actionComponents={
          <Button size="sm" onClick={onOpenForm}>
            Add Working Experience
          </Button>
        }
      />
      <TableList
        resources={workingExperiences}
        dataArrayRender={(workingExperience) => {
          return [
            workingExperience.data.companyName,
            moment(workingExperience.data.start).format('YYYY-MM-DD'),
            moment(workingExperience.data.end).format('YYYY-MM-DD'),
          ]
        }}
        headings={['Company Name', 'Start Date', 'End Date']}
        actions={(workingExperience, index) => (
          <Button
            onClick={() => {
              setSeletedIndex(index)
              setSelectedItem(workingExperience)
              onOpenForm()
            }}
            size="sm"
          >
            Edit
          </Button>
        )}
        deleteFunc={(_, index) => {
          confirmation({
            description: 'Are you sure want to delete the data?',
            callback: () => {
              deleteItem(index)
              close()
            },
          })
        }}
      />

      <ActionButtons
        mt={10}
        onBack={onBack}
        onNext={() => onNext(workingExperiences.map((elm) => elm.data))}
      />

      <ModalWorkingExperienceForm
        data={selectedItem?.data}
        isOpen={isOpenForm}
        onClose={() => {
          onCloseForm()
          setSelectedItem(null)
        }}
        onSubmit={onLocalSubmit}
      />
    </>
  )
}

export default WorkingExperience
