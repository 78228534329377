import WorkingExperienceForm from './WorkingExperienceForm'
import ModalComp from '../Modal'
import { ModalWorkingExperienceFormProps } from './interface'

const ModalWorkingExperienceForm: React.FC<ModalWorkingExperienceFormProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <ModalComp isOpen={isOpen} onClose={onClose} headerText="Working Experience">
      <WorkingExperienceForm {...props} />
    </ModalComp>
  )
}

export default ModalWorkingExperienceForm
