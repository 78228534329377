import { extendTheme } from '@chakra-ui/react'
import Button from './Button'
import Heading from './Heading'
import Text from './Text'
import Input from './Input'
import Form from './Form'
import FormLabel from './FormLabel'
import Alert from './Alert'

const fonts = {
  heading: `'Inter', sans-serif`,
  body: `'Inter', sans-serif`,
}

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        color: 'darkestBlue',
      },
    }),
  },
  components: {
    Button,
    Heading,
    Text,
    Input,
    //Textarea,
    Form,
    FormLabel,
    Alert,
  },
  fonts,
})

export default theme
