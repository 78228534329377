import { Box, HStack, IconButton } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import React from 'react'
import { ArrowPaginationProps } from './interface'

const ArrowPagination: React.FC<ArrowPaginationProps> = ({
  paginator,
  onPrev,
  onNext,
  ...props
}) => {
  return (
    <HStack {...props}>
      <Box>
        {paginator?.firstItem}-{paginator?.lastItem} dari {paginator?.total}
      </Box>
      <HStack>
        <IconButton
          aria-label="Tombol Halaman Sebelumnya"
          title="Sebelumnya"
          size="sm"
          variant="outline"
          icon={<ChevronLeftIcon />}
          isDisabled={paginator?.currentPage === 1}
          onClick={() => {
            if (onPrev) {
              onPrev((paginator?.currentPage || 1) - 1)
            }
          }}
        />

        <IconButton
          aria-label="Tombol Halaman Berikutnya"
          title="Berikutnya"
          size="sm"
          variant="outline"
          icon={<ChevronRightIcon />}
          isDisabled={!paginator?.hasMorePages}
          onClick={() => {
            if (onNext) {
              onNext((paginator?.currentPage || 1) + 1)
            }
          }}
        />
      </HStack>
    </HStack>
  )
}

export default ArrowPagination
