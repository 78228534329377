import React, { useRef } from 'react'
import ModalPopupImage from '../ModalPopupImage'
import {
  Box,
  Button,
  Flex,
  Image,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { CameraIcon, DeleteIcon } from '../Icon'
import { ImageUploaderProps } from './interface'

const ImageUploader: React.FC<ImageUploaderProps> = ({
  accept,
  fileUrl,
  isLoading,
  onChange,
  onDelete,
}) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onHandleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      if (onChange) {
        onChange(e.target.files[0])
      }
    }
  }

  return (
    <>
      {fileUrl && (
        <ModalPopupImage
          src={fileUrl}
          isOpen={isOpen}
          onClose={() => {
            onClose()
          }}
        />
      )}

      <Flex gap="20px">
        <Box
          h="100px"
          w="100px"
          borderRadius="8px"
          overflow="hidden"
          border="1px solid var(--chakra-colors-gray-300)"
          cursor={fileUrl ? 'pointer' : 'inherit'}
          onClick={() => {
            onOpen()
          }}
          position="relative"
        >
          {isLoading && (
            <Flex
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              bgColor="rgba(255, 255, 255, 0.9)"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner w="26px" />
            </Flex>
          )}

          {fileUrl ? (
            <Image
              src={fileUrl}
              alt="Preview"
              w="100px"
              h="100px"
              objectFit="contain"
            />
          ) : (
            <Flex
              w="100px"
              h="100px"
              alignItems="center"
              justifyContent="center"
            >
              <CameraIcon boxSize="30px" />
            </Flex>
          )}
        </Box>

        <Box>
          {fileUrl ? (
            <Button
              size="sm"
              type="button"
              title="Delete Image"
              onClick={() => {
                if (onDelete) {
                  onDelete()
                }
              }}
              leftIcon={<DeleteIcon />}
              isDisabled={isLoading}
            >
              Delete Image
            </Button>
          ) : (
            <Button
              size="sm"
              mb="10px"
              onClick={() => {
                if (hiddenFileInputRef.current) {
                  hiddenFileInputRef.current.click()
                }
              }}
              isDisabled={isLoading}
            >
              Upload
            </Button>
          )}
          <Text fontSize="15px" mt="10px">
            Accepted format JPG or PNG only with maximum size 2MB.
          </Text>
        </Box>
      </Flex>

      <input
        type="file"
        ref={hiddenFileInputRef}
        onChange={onHandleFileChange}
        accept={accept}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default ImageUploader
