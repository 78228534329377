import React, { forwardRef } from 'react'
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom'
import { Link, LinkProps } from '@chakra-ui/react'

type RouterLinkProps = ReactRouterLinkProps & LinkProps

const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  ({ children, ...props }, ref) => {
    return (
      <Link
        as={ReactRouterLink}
        ref={ref}
        _hover={{
          textDecoration: 'none',
        }}
        {...props}
      >
        {children}
      </Link>
    )
  }
)

export default RouterLink
