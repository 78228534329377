import { Button, SimpleGrid } from '@chakra-ui/react'
import { EducationFormProps, IFormData } from './interface'
import { useForm } from 'react-hook-form'
import { FieldType, ICvFormField } from '../../interfaces/cv'
import FormFields from '../FormFields'

const EducationForm: React.FC<EducationFormProps> = ({ data, onSubmit }) => {
  const form = useForm<IFormData>({
    defaultValues: data || {},
  })

  const { handleSubmit } = form

  const fields: ICvFormField[] = [
    {
      name: 'educationLevel',
      label: 'Education Level',
      type: FieldType.TEXT,
      templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI'],
    },
    {
      name: 'schoolName',
      label: 'School Name',
      type: FieldType.TEXT,
      alwaysShow: true,
      required: true,
      templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
    },
    {
      name: 'start',
      label: 'Start',
      type: FieldType.DATEPICKER,
      alwaysShow: true,
      required: true,
      templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
    },
    {
      name: 'end',
      label: 'End',
      type: FieldType.DATEPICKER,
      alwaysShow: true,
      required: true,
      templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
    },
    {
      name: 'highestStandardAttained',
      label: 'Highest Standard Attained',
      type: FieldType.TEXT,
      templates: ['CMSI', 'ROYAL'],
    },
  ]

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <SimpleGrid columns={2} spacing={10}>
        <FormFields fields={fields} hookForm={form} />
      </SimpleGrid>

      <Button type="submit">Submit</Button>
    </form>
  )
}

export default EducationForm
