import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { FormProps, IVisaItem } from './interface'
import ActionButtons from '../../ActionButtons'
import TableList from '../../../../components/TableList'
import ModalVisaForm from '../../../../components/ModalVisaForm'
import TableListTitle from '../../../../components/TableList/TableListTitle'
import { useConfirmation } from '../../../../context/ConfirmationContext'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import { IFormData } from '../../../../components/ModalVisaForm/interface'
import moment from 'moment'

const defaultVisas = [
  'Schengen Multi / Single USA',
  'USA (C1/D)',
  'Type D Permit',
]

const Visa: React.FC<FormProps> = ({ onBack, onNext, data = [] }) => {
  const {
    isOpen: isOpenForm,
    onOpen: onOpenForm,
    onClose: onCloseForm,
  } = useDisclosure()

  const [visas, setVisas] = useState<IVisaItem[]>(
    data
      ? data.map((elm) => {
          return {
            rowItemId: uuidv4(),
            data: { ...elm, isDefault: defaultVisas.includes(elm.name) },
          }
        })
      : defaultVisas.map((elm) => ({
          rowItemId: uuidv4(),
          data: {
            name: elm,
            number: '',
            issueCity: '',
            issueCountry: '',
            issueDate: null,
            expiryDate: null,
            images: [],
            isDefault: true,
          },
        }))
  )

  const { confirmation, close } = useConfirmation()

  const [selectedIndex, setSeletedIndex] = useState<number>(-1)
  const [selectedItem, setSelectedItem] = useState<IVisaItem | null>(null)

  const onLocalSubmit = (itemData: IFormData) => {
    let nextVisas = null
    if (selectedItem !== null) {
      nextVisas = update(visas, {
        [selectedIndex]: {
          data: { $set: itemData },
        },
      })
    } else {
      nextVisas = update(visas, {
        $push: [{ rowItemId: uuidv4(), data: itemData }],
      })
    }

    setVisas(nextVisas)
    onCloseForm()
    setSelectedItem(null)
  }

  const deleteItem = (index: number) => {
    const nextVisas = update(visas, {
      $splice: [[index, 1]],
    })

    setVisas(nextVisas)
  }

  return (
    <>
      <TableListTitle
        title="Visa List"
        actionComponents={
          <Button size="sm" onClick={onOpenForm}>
            Add Visa
          </Button>
        }
      />
      <TableList
        resources={visas}
        dataArrayRender={(visa) => {
          return [
            visa.data.name,
            visa.data.number,
            visa.data.issueCity,
            visa.data.issueCountry,
            visa.data.issueDate
              ? moment(visa.data.issueDate).format('YYYY-MM-DD')
              : '',
            visa.data.expiryDate
              ? moment(visa.data.expiryDate).format('YYYY-MM-DD')
              : '',
          ]
        }}
        headings={[
          'Name',
          'Number',
          'Issue City',
          'Issue Country',
          'Issue Date',
          'Expiry Date',
          '',
        ]}
        actions={(visa, index) => (
          <Flex gap={4}>
            <Button
              onClick={() => {
                setSeletedIndex(index)
                setSelectedItem(visa)
                onOpenForm()
              }}
              size="sm"
            >
              Edit
            </Button>
            {!visa.data.isDefault && (
              <Button
                variant="link"
                onClick={() => {
                  confirmation({
                    description: 'Are you sure want to delete the data?',
                    callback: () => {
                      deleteItem(index)
                      close()
                    },
                  })
                }}
              >
                Delete
              </Button>
            )}
          </Flex>
        )}
      />
      <ActionButtons
        mt={10}
        onBack={onBack}
        onNext={() => onNext(visas.map((elm) => elm.data))}
      />
      <ModalVisaForm
        data={selectedItem?.data}
        isOpen={isOpenForm}
        onClose={() => {
          onCloseForm()
          setSelectedItem(null)
        }}
        onSubmit={onLocalSubmit}
      />
    </>
  )
}

export default Visa
