import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import {
  ConfirmationProviderProps,
  IConfirmationContext,
  IModalConfirmation,
} from './interface'

const ConfirmationContext = React.createContext<IConfirmationContext>({
  confirmation: () => {},
  setIsLoading: () => false,
  close: () => {},
})

const ConfirmationProvider: React.FC<ConfirmationProviderProps> = ({
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)

  const [modalConfirmation, setModalConfirmation] =
    useState<IModalConfirmation | null>(null)

  const confirmation = (value: IModalConfirmation) => {
    setModalConfirmation(value)
    onOpen()
  }

  return (
    <ConfirmationContext.Provider
      value={{ confirmation, setIsLoading, close: onClose }}
    >
      {children}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          setModalConfirmation(null)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalConfirmation?.title || 'Confirmation'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <VStack spacing={4} mt={3} mb={5}>
                <Spinner size="xl" />
                <Box>Mohon tunggu...</Box>
              </VStack>
            ) : (
              <>{modalConfirmation?.description}</>
            )}
          </ModalBody>
          {!isLoading && (
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  if (modalConfirmation && modalConfirmation.callback) {
                    modalConfirmation?.callback()
                  }
                }}
              >
                Continue
              </Button>
              <Button variant="clean" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </ConfirmationContext.Provider>
  )
}

export default ConfirmationContext

const useConfirmation = () => useContext(ConfirmationContext)

export { ConfirmationProvider, useConfirmation }
