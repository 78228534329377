import { Box, HStack } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import RouterLink from '../RouterLink'
import TBodyPlaceholder from '../TBodyPlaceholder'
import { TableListProps } from './interface'

function TableList<T>({
  resources,
  loading,
  headings,
  dataArrayRender,
  actions,
  editLink,
  deleteFunc,
}: TableListProps<T>) {
  return (
    <Box overflow="auto">
      <Table>
        <Thead>
          <Tr>
            {headings.map((heading, index) => (
              <Th key={`heading-${index}`} whiteSpace="nowrap">
                {heading}
              </Th>
            ))}
            {(editLink || deleteFunc) && <Th />}
          </Tr>
        </Thead>
        {loading && (
          <TBodyPlaceholder
            rowNumber={5}
            colNumber={headings.length}
            randomWidth={[
              [150, 200, 250],
              [220, 260, 300],
            ]}
          />
        )}
        {resources && !loading && (
          <Tbody>
            {!resources || resources.length < 1 ? (
              <Tr>
                <Td colSpan={headings.length}>There is no data!</Td>
              </Tr>
            ) : (
              <>
                {resources.map((resource, index: number) => {
                  return (
                    <Tr key={`data-row-${index}`}>
                      {dataArrayRender(resource, index).map(
                        (element, indexEl) => (
                          <Td
                            key={`data-col-${index}-${indexEl}`}
                            fontSize={{ base: 'sm', md: 'md' }}
                            whiteSpace="nowrap"
                          >
                            {element}
                          </Td>
                        )
                      )}

                      {(editLink || deleteFunc || actions) && (
                        <Td>
                          <HStack justifyContent="flex-end" spacing={6}>
                            {actions && actions(resource, index)}
                            {editLink && (
                              <RouterLink
                                to={editLink(resource, index)}
                                fontSize="1rem"
                              >
                                Edit
                              </RouterLink>
                            )}

                            {deleteFunc && (
                              <>
                                <Button
                                  variant="clean"
                                  onClick={() => {
                                    deleteFunc(resource, index)
                                  }}
                                >
                                  Delete
                                </Button>
                              </>
                            )}
                          </HStack>
                        </Td>
                      )}
                    </Tr>
                  )
                })}
              </>
            )}
          </Tbody>
        )}
      </Table>
    </Box>
  )
}

export default TableList
