import { Component, useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { IFormData } from './interface'
import CvForm from './Form'
import PageTitle from '../../components/PageTitle'
import { useFormEdit } from '../../hooks/formEdit'
import { Spinner, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  Cv,
  useGetCvLazyQuery,
  useUpdateCvMutation,
} from '../../generated/graphql'
import moment from 'moment'
import { IFormData as IFormDataPersonalData } from './screens/PersonalData/interface'
import { FormCvProvider, useFormCv } from './FormContext'

const ProductEdit: React.FC = () => {
  const { templates, setTemplates } = useFormCv()
  const { cvId } = useParams<{ cvId: string }>()
  const toast = useToast()
  const [getCv] = useGetCvLazyQuery({ variables: { id: cvId as string } })
  const [updateCv] = useUpdateCvMutation()

  const navigate = useNavigate()

  const dataCallback: () => Promise<Cv> = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getCv()
        resolve(res.data?.getCv as Cv)
      } catch (err) {
        reject(err)
      }
    })
  }, [getCv])

  const submitCallback = async (_: Cv, formData: IFormData) => {
    try {
      const {
        personalData,
        additionalInfo,
        nextOfKins,
        applyInfo,
        educations,
        certificates,
        visas,
        documents,
        languages,
        workingExperiences,
      } = formData

      const inputPersonalData = {
        ...(personalData as IFormDataPersonalData),
        dateOfBirth: moment(personalData?.dateOfBirth).format('YYYY-MM-DD'),
        height: parseInt((personalData?.height || '').toString()),
        weight: parseInt((personalData?.weight || '').toString()),
      }

      const applyInfoInput = {
        ...applyInfo,
        date: applyInfo?.date
          ? moment(applyInfo.date).format('YYYY-MM-DD')
          : '',
      }

      const inputTemplates = templates.map((elm) => elm)

      const inputEducations = educations?.map((elm) => ({
        ...elm,
        start: elm.start ? moment(elm.start).format('YYYY-MM-DD') : '',
        end: elm.end ? moment(elm.end).format('YYYY-MM-DD') : '',
      }))

      const inputCertificates = certificates?.map(({ isDefault, ...elm }) => ({
        ...elm,
        issueDate: elm.issueDate
          ? moment(elm.issueDate).format('YYYY-MM-DD')
          : '',
        expiryDate: elm.expiryDate
          ? moment(elm.expiryDate).format('YYYY-MM-DD')
          : '',
      }))

      const inputVisas = visas?.map(({ isDefault, ...elm }) => ({
        ...elm,
        issueDate: elm.issueDate
          ? moment(elm.issueDate).format('YYYY-MM-DD')
          : '',
        expiryDate: elm.expiryDate
          ? moment(elm.expiryDate).format('YYYY-MM-DD')
          : '',
      }))

      const inputDocuments = documents?.map(({ isDefault, ...elm }) => ({
        ...elm,
        issueDate: elm.issueDate
          ? moment(elm.issueDate).format('YYYY-MM-DD')
          : '',
        expiryDate: elm.expiryDate
          ? moment(elm.expiryDate).format('YYYY-MM-DD')
          : '',
      }))

      const inputLanguages = languages?.map(({ isDefault, ...elm }) => ({
        ...elm,
      }))

      const inputWorkingExperiences = workingExperiences?.map(({ ...elm }) => ({
        ...elm,
        start: moment(elm.start).format('YYYY-MM-DD'),
        end: moment(elm.end).format('YYYY-MM-DD'),
      }))

      await updateCv({
        variables: {
          id: cvId as string,
          input: {
            ...inputPersonalData,
            applyInfo: applyInfoInput,
            additionalInfo,
            nextOfKins,
            templates: inputTemplates,
            educations: inputEducations,
            certificates: inputCertificates,
            visas: inputVisas,
            documents: inputDocuments,
            languages: inputLanguages,
            workingExperiences: inputWorkingExperiences,
          },
        },
      })

      toast({
        description: 'CV has been updated successfully!',
        status: 'success',
      })
    } catch (err) {
      console.log('Update CV Error: ', err)

      toast({
        description: 'Error',
        status: 'error',
      })
    }
  }

  const { isLoadingData, data, onSubmit, isLoadingSubmit } = useFormEdit<
    Cv,
    IFormData
  >({
    dataCallback,
    submitCallback,
    onSubmitSucess: () => {
      navigate('/cv')
    },
  })

  useEffect(() => {
    if (data) {
      setTemplates(data.templates || [])
    }
  }, [data, setTemplates])

  return (
    <>
      <PageTitle>Edit CV</PageTitle>
      {isLoadingData ? (
        <Spinner />
      ) : (
        <CvForm
          data={data}
          onSubmit={onSubmit}
          loading={isLoadingSubmit}
          photoUrl={data?.photoUrl || ''}
        />
      )}
    </>
  )
}

const withProvider = (WrappedComponent: any) => {
  class HOC extends Component {
    render() {
      return (
        <FormCvProvider>
          <WrappedComponent />
        </FormCvProvider>
      )
    }
  }

  return HOC
}

export default withProvider(ProductEdit)
