import { useCallback } from 'react'
import { useParams } from 'react-router'
import { IFormData } from './interface'
import UserForm from './Form'
import PageTitle from '../../components/PageTitle'
import { useFormEdit } from '../../hooks/formEdit'
import { Spinner, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  User,
  useGetUserLazyQuery,
  useUpdateUserMutation,
} from '../../generated/graphql'

const UserEdit: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const toast = useToast()
  const [getUser] = useGetUserLazyQuery({ variables: { id: userId as string } })
  const [updateUser] = useUpdateUserMutation()

  const navigate = useNavigate()

  const dataCallback: () => Promise<User> = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await getUser()
        resolve(res.data?.getUser as User)
      } catch (err) {
        reject(err)
      }
    })
  }, [getUser])

  const submitCallback = async (_: User, formData: IFormData) => {
    const { name, email, password, level } = formData

    try {
      await updateUser({
        variables: {
          id: userId as string,
          input: {
            name,
            email,
            password: password || undefined,
            level,
          },
        },
      })

      toast({
        description: 'User has been updated successfully!',
        status: 'success',
      })
    } catch (err) {
      toast({
        status: 'error',
        description: 'Email is already exists!',
      })
    }
  }

  const { isLoadingData, data, onSubmit, isLoadingSubmit } = useFormEdit<
    User,
    IFormData
  >({
    dataCallback,
    submitCallback,
    onSubmitSucess: () => {
      navigate('/user')
    },
  })

  return (
    <>
      <PageTitle>Edit User</PageTitle>
      {isLoadingData ? (
        <Spinner />
      ) : (
        <UserForm data={data} onSubmit={onSubmit} loading={isLoadingSubmit} />
      )}
    </>
  )
}

export default UserEdit
