import { Box, Button, Flex } from '@chakra-ui/react'
import { ActionButtonsProps } from './interface'

const ActionButtons: React.FC<ActionButtonsProps> = ({
  hideBack = false,
  isSubmitButton = false,
  onBack,
  onNext,
  ...props
}) => {
  return (
    <Flex
      justifyContent="space-between"
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      backgroundColor="#fff"
      borderTop="1px solid #dedede"
      py="10px"
      px="30px"
      {...props}
    >
      <Box>
        {!hideBack && (
          <Button type="button" onClick={onBack}>
            Back
          </Button>
        )}
      </Box>
      <Box>
        <Flex gap={3}>
          {/*<Button type="submit">Save to Draft</Button>*/}
          {isSubmitButton ? (
            <Button type="submit" value="next">
              Next
            </Button>
          ) : (
            <Button type="button" value="next" onClick={onNext}>
              Next
            </Button>
          )}
        </Flex>
      </Box>
    </Flex>
  )
}

export default ActionButtons
