import { ModalEducationFormProps } from './interface'
import CertificateForm from './CertificateForm'
import ModalComp from '../Modal'

const ModalCertificateForm: React.FC<ModalEducationFormProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <ModalComp isOpen={isOpen} onClose={onClose} headerText="Certificate">
      <CertificateForm {...props} />
    </ModalComp>
  )
}

export default ModalCertificateForm
