import React from 'react'
import { Navigate, Outlet, RouteProps } from 'react-router-dom'

type IProtectedRouteProps = RouteProps & { isLoggedIn: any }
const ProtectedRouteAuth: React.FC<IProtectedRouteProps> = ({ isLoggedIn }) => {
  if (isLoggedIn) {
    return <Navigate to="/" />
  }

  return <Outlet />
}

export default ProtectedRouteAuth
