import { FieldType, ICvFormField } from '../../../../interfaces/cv'

export const fields: ICvFormField[] = [
  {
    name: 'position',
    label: 'Apply for Position',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
  {
    name: 'department',
    label: 'Apply for Department',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
  },
]
