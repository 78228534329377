import { useCallback, useEffect, useState } from 'react'

interface IUseFormEdit<T, D> {
  dataCallback: () => Promise<T>
  submitCallback: (data: T, formData: D) => Promise<any>
  onSubmitSucess?: () => void
}

export function useFormEdit<T, D>({
  dataCallback,
  submitCallback,
  onSubmitSucess,
}: IUseFormEdit<T, D>) {
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const [data, setData] = useState<T>()

  const callGetData = useCallback(async () => {
    if (dataCallback) {
      setIsLoadingData(true)

      try {
        const _data = await dataCallback()
        setData(_data)
      } catch (err) {
        console.log('__err', err)
      } finally {
        setIsLoadingData(false)
      }
    }
  }, [dataCallback])

  useEffect(() => {
    callGetData()
  }, [callGetData])

  const onSubmit = async (formData: D) => {
    try {
      await submitCallback(data as T, formData)

      if (onSubmitSucess) onSubmitSucess()
    } catch (err) {
      console.log('Error edit: ', err)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  return { isLoadingData, data, isLoadingSubmit, onSubmit }
}
