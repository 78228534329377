import { ModalNextOfKinFormProps } from './interface'
import NextOfKinForm from './NextOfKinForm'
import ModalComp from '../Modal'

const ModalNextOfKinForm: React.FC<ModalNextOfKinFormProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <ModalComp isOpen={isOpen} onClose={onClose} headerText="Document">
      <NextOfKinForm {...props} />
    </ModalComp>
  )
}

export default ModalNextOfKinForm
