import React from 'react'
import { Global } from '@emotion/react'

const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={`
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
      `}
    />
  )
}

export default GlobalStyles
