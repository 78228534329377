import { useCallback, useEffect, useState } from 'react'
import { Button, Image, useDisclosure, useToast } from '@chakra-ui/react'
import ProductFilter from './CVFilter'
import RouterLink from '../../components/RouterLink'
import queryString from 'query-string'
import TableList from '../../components/TableList'
import { useLocation, useNavigate } from 'react-router-dom'
import { useConfirmation } from '../../context/ConfirmationContext'
import CardForm from '../../components/CardForm'
import TableListTitle from '../../components/TableList/TableListTitle'
import TableListPagination from '../../components/TableList/TableListPagination'
import { useQueryParams } from '../../hooks/queryParams'
import {
  Cv,
  PaginatorInfo,
  useDeleteCvMutation,
  useListCvsQuery,
} from '../../generated/graphql'
import ModalDownload from '../../components/ModalDownload'

const CVList: React.FC = () => {
  const location = useLocation()

  const toast = useToast()

  const { pathname } = location

  const { confirmation, setIsLoading, close } = useConfirmation()

  const navigate = useNavigate()

  const [selectedItem, setSelectedItem] = useState<Cv>()

  const {
    isOpen: isOpenDownload,
    onClose: onCloseDownload,
    onOpen: onOpenDownload,
  } = useDisclosure()

  const [paginator, setPaginator] = useState<PaginatorInfo>()

  const { queryParams } = useQueryParams<{
    page?: string
    key?: string
  }>()

  const { data, refetch, loading } = useListCvsQuery()

  useEffect(() => {
    refetch({
      page: parseInt((queryParams?.page || 1) as string),
      key: queryParams?.key ? `%${queryParams.key}%` : undefined,
    })
  }, [queryParams, refetch])

  const [deleteCv] = useDeleteCvMutation()

  useEffect(() => {
    setPaginator(data?.listCvs.paginatorInfo)
  }, [data])

  const deleteRow = useCallback(
    async (id: string) => {
      try {
        await deleteCv({ variables: { id } })

        toast({
          description: 'CV has been deleted successfully!',
          status: 'success',
        })

        refetch()
      } catch (err) {
        console.log('Delete CV Error: ', err)

        toast({
          description: 'Error',
          status: 'error',
        })
      } finally {
      }
    },
    [refetch, deleteCv, toast]
  )

  const onPageChange = (page: number) => {
    const url = queryString.stringifyUrl({
      url: pathname,
      query: {
        page,
      },
    })

    navigate(url)
  }

  return (
    <>
      <CardForm px="0" py="0">
        <TableListTitle
          title="CV"
          actionComponents={
            <Button as={RouterLink} to="/cv/add" size="sm">
              Create CV
            </Button>
          }
        />

        <TableListPagination
          paginationProps={{
            paginator: paginator as PaginatorInfo,
            onPrev: (value: any) => {
              if (onPageChange) {
                onPageChange(value)
              }
            },
            onNext: (value: any) => {
              if (onPageChange) {
                onPageChange(value)
              }
            },
          }}
          filterComponents={
            <ProductFilter
              onSubmit={(data) => {
                const url = queryString.stringifyUrl({
                  url: pathname,
                  query: { ...data, page: 1 },
                })

                navigate(url)
              }}
              value={queryParams}
            />
          }
        />
        <TableList
          loading={loading}
          resources={(data?.listCvs.data as Cv[]) || []}
          dataArrayRender={(cv: Cv) => {
            return [
              cv.id,
              cv.photoUrl ? (
                <Image
                  src={cv.photoUrl}
                  w="100px"
                  border="1px solid #dedede"
                  borderRadius="6px"
                />
              ) : (
                <></>
              ),
              cv.firstName,
              cv.lastName,
              cv.email,
              cv.dateOfBirth,
              cv.gender,
            ]
          }}
          headings={[
            'ID',
            'Photo',
            'First Name',
            'Last Name',
            'Email',
            'Date of Birth',
            'Gender',
          ]}
          editLink={(resource: Cv) => {
            return `/cv/${resource.id}/edit`
          }}
          actions={(resource) => (
            <Button
              onClick={() => {
                setSelectedItem(resource)
                onOpenDownload()
              }}
              variant="clean"
            >
              Download
            </Button>
          )}
          deleteFunc={(resource: Cv) => {
            confirmation({
              description: 'Are you sure want to delete the data?',
              callback: async () => {
                setIsLoading(true)
                await deleteRow(resource.id)
                setIsLoading(false)
                close()
              },
            })
          }}
        />
      </CardForm>
      {selectedItem && (
        <ModalDownload
          item={selectedItem}
          isOpen={isOpenDownload}
          onClose={onCloseDownload}
        />
      )}
    </>
  )
}

export default CVList
