const Heading = {
  baseStyle: {
    fontSize: {
      base: "0.86rem",
      lg: "1rem",
    },
  },
};

export default Heading;
