import { Button, SimpleGrid } from '@chakra-ui/react'
import { useMemo } from 'react'
import { NextOfKinFormProps, IFormData } from './interface'
import { useForm } from 'react-hook-form'
import { FieldType, ICvFormField } from '../../interfaces/cv'
import FormFields from '../FormFields'

const NextOfKinForm: React.FC<NextOfKinFormProps> = ({ data, onSubmit }) => {
  const form = useForm<IFormData>({
    defaultValues: data || {},
  })

  const { handleSubmit } = form

  const fields: ICvFormField[] = useMemo(() => {
    return [
      {
        name: 'name',
        label: 'Name',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'relation',
        label: 'Relation',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'address',
        label: 'Address',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'age',
        label: 'Age',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'employerName',
        label: 'Employer Name',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'city',
        label: 'City',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'state',
        label: 'State',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'postalCode',
        label: 'Postal Code',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'country',
        label: 'Country',
        type: FieldType.COUNTRY,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'phone',
        label: 'Phone',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'mobile',
        label: 'Mobile',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'email',
        label: 'Email',
        type: FieldType.EMAIL,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
    ]
  }, [])

  // const filteredFields = fields.filter((elm) => {
  //   return (
  //     elm.alwaysShow || templates.some((elm2) => elm.templates?.includes(elm2))
  //   )
  // })

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <SimpleGrid columns={2} spacing={10}>
        <FormFields fields={fields} hookForm={form} />
      </SimpleGrid>

      <Button type="submit">Submit</Button>
    </form>
  )
}

export default NextOfKinForm
