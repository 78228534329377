import React from 'react'
import { ComponentWithAs, Icon, IconProps } from '@chakra-ui/react'

const IconComp: ComponentWithAs<'svg', IconProps> = ({
  children,
  ...props
}) => {
  return (
    <Icon viewBox="0 0 341.333 341.333" boxSize="20px" {...props}>
      <rect y="277.333" width="341.333" height="42.667" />
      <rect y="149.333" width="341.333" height="42.667" />
      <rect y="21.333" width="341.333" height="42.667" />
    </Icon>
  )
}

export default IconComp
