import { Button, SimpleGrid } from '@chakra-ui/react'
import { useMemo } from 'react'
import { DocumentFormProps, IFormData } from './interface'
import { useForm } from 'react-hook-form'
import { FieldType, ICvFormField } from '../../interfaces/cv'
import FormFields from '../FormFields'

const DocumentForm: React.FC<DocumentFormProps> = ({ data, onSubmit }) => {
  const form = useForm<IFormData>({
    defaultValues: data || {},
  })

  const { handleSubmit } = form

  const fields: ICvFormField[] = useMemo(() => {
    return [
      {
        name: 'name',
        label: 'Name',
        type: FieldType.TEXT,
        required: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI'],
        readOnly: data?.isDefault,
      },
      {
        name: 'number',
        label: 'Number',
        type: FieldType.TEXT,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'issueCity',
        label: 'Issue City',
        type: FieldType.TEXT,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'issueCountry',
        label: 'Issue Country',
        type: FieldType.COUNTRY,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'issueDate',
        label: 'Issue Date',
        type: FieldType.DATEPICKER,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'expiryDate',
        label: 'Expiry Date',
        type: FieldType.DATEPICKER,
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
    ]
  }, [])

  // const filteredFields = fields.filter((elm) => {
  //   return (
  //     elm.alwaysShow || templates.some((elm2) => elm.templates?.includes(elm2))
  //   )
  // })

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <SimpleGrid columns={2} spacing={10}>
        <FormFields fields={fields} hookForm={form} />
      </SimpleGrid>

      <Button type="submit">Submit</Button>
    </form>
  )
}

export default DocumentForm
