import React from 'react'
import { Heading, HeadingProps } from '@chakra-ui/react'

const CardFormTitle: React.FC<HeadingProps> = ({ children, ...props }) => {
  return (
    <Heading
      as="h3"
      fontWeight="600"
      fontSize="18px"
      textAlign="center"
      mb="24px"
      {...props}
    >
      {children}
    </Heading>
  )
}

export default CardFormTitle
