import { SimpleGrid } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { FormProps, IFormData } from './interface'
import ActionButtons from '../../ActionButtons'
import { fields } from './constants'
import FormFields from '../../../../components/FormFields'
//import { useFormCv } from '../../FormContext'

const ApplyInfo: React.FC<FormProps> = ({ onBack, onSubmit, data }) => {
  //const { templates } = useFormCv()

  const personalDataForm = useForm<IFormData>({
    defaultValues: data || {},
  })

  const { handleSubmit } = personalDataForm

  // const filteredFields = fields.filter((elm) => {
  //   return (
  //     elm.alwaysShow || templates.some((elm2) => elm.templates?.includes(elm2))
  //   )
  // })

  const filteredFields = fields

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <SimpleGrid columns={2} spacing={10}>
        <FormFields fields={filteredFields} hookForm={personalDataForm} />
      </SimpleGrid>

      <ActionButtons mt={10} onBack={onBack} isSubmitButton />
    </form>
  )
}

export default ApplyInfo
