const Input = {
  baseStyle: {
    h: {
      base: '48px',
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: '#c2c4cc',
        //borderRadius: "5px",
        _focus: {
          borderColor: '#333',
          boxShadow: 'none',
        },
      },
    },
  },
  sizes: {
    sm: {
      field: {
        borderRadius: '6px',
        fontSize: '16px',
        h: {
          base: '38px',
        },
        padding: '12px 23px',
      },
    },
    md: {
      field: {
        borderRadius: '8px',
        fontSize: '16px',
        h: {
          base: '50px',
        },
        px: '16px',
        py: '12px',
      },
    },
  },
  // defaultProps: {
  //   size: 'sm',
  //   variant: 'solid',
  // },
}

export default Input
