const Text = {
  baseStyle: {
    fontSize: {
      //base: '0.86rem',
      //lg: "1rem",
    },
  },
};

export default Text;
