import { ModalDocumentFormProps } from './interface'
import DocumentForm from './DocumentForm'
import ModalComp from '../Modal'

const ModalDocumentForm: React.FC<ModalDocumentFormProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <ModalComp isOpen={isOpen} onClose={onClose} headerText="Document">
      <DocumentForm {...props} />
    </ModalComp>
  )
}

export default ModalDocumentForm
