import { Button, SimpleGrid } from '@chakra-ui/react'
import { useMemo } from 'react'
import { LanguageFormProps, IFormData } from './interface'
import { useForm } from 'react-hook-form'
import { FieldType, ICvFormField } from '../../interfaces/cv'
import FormFields from '../FormFields'

const LanguageForm: React.FC<LanguageFormProps> = ({ data, onSubmit }) => {
  const form = useForm<IFormData>({
    defaultValues: data || {},
  })

  const { handleSubmit } = form

  const fields: ICvFormField[] = useMemo(() => {
    return [
      {
        name: 'name',
        label: 'Name',
        type: FieldType.TEXT,
        required: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI'],
        readOnly: data?.isDefault,
      },
      {
        name: 'spoken',
        label: 'Spoken',
        type: FieldType.SELECT,
        options: [
          {
            value: 'Fair',
            label: 'Fair',
          },
          {
            value: 'Well',
            label: 'Well',
          },
          {
            value: 'Excellent',
            label: 'Excellent',
          },
        ],
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'written',
        label: 'Written',
        type: FieldType.SELECT,
        options: [
          {
            value: 'Fair',
            label: 'Fair',
          },
          {
            value: 'Well',
            label: 'Well',
          },
          {
            value: 'Excellent',
            label: 'Excellent',
          },
        ],
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'reading',
        label: 'Reading',
        type: FieldType.SELECT,
        options: [
          {
            value: 'Fair',
            label: 'Fair',
          },
          {
            value: 'Well',
            label: 'Well',
          },
          {
            value: 'Excellent',
            label: 'Excellent',
          },
        ],
        alwaysShow: true,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
    ]
  }, [])

  // const filteredFields = fields.filter((elm) => {
  //   return (
  //     elm.alwaysShow || templates.some((elm2) => elm.templates?.includes(elm2))
  //   )
  // })

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <SimpleGrid columns={2} spacing={10}>
        <FormFields fields={fields} hookForm={form} />
      </SimpleGrid>

      <Button type="submit">Submit</Button>
    </form>
  )
}

export default LanguageForm
