import { FieldType, ICvFormField } from '../../../../interfaces/cv'

export const fields: ICvFormField[] = [
  {
    name: 'homeAirportCity',
    label: 'Home Airport City',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'closestInternationalAirport',
    label: 'Closest International Airport',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'timeNeededToReachAirport',
    label: 'Time needed to reach Airport',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'presentEmployment',
    label: 'Present Employment',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'presentEmploymentSinceWhen',
    label: 'Since When',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'everApplied',
    label: 'Ever applied to this Company before?',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'everAppliedWhen',
    label: 'When?',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'recruitmentAgency',
    label: 'Recruitment Agency',
    type: FieldType.TEXT,
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'tattoo',
    label: 'Tattoo',
    type: FieldType.BOOLEANTEXT,
    placeholder: 'Tattoo Location',
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'currentMedication',
    label: 'Current Medication',
    type: FieldType.BOOLEANTEXT,
    placeholder: 'Current Medication',
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'medicalProblems',
    label: 'Medical Problems',
    type: FieldType.BOOLEANTEXT,
    placeholder: 'Medical Problems',
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'medication',
    label: 'Medication',
    type: FieldType.BOOLEANTEXT,
    placeholder: 'Medication',
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'hearingProblem',
    label: 'Hearing Problem',
    type: FieldType.BOOLEANTEXT,
    placeholder: 'Hearing Problem',
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'visionProblem',
    label: 'Vision Problem',
    type: FieldType.BOOLEANTEXT,
    placeholder: 'Vision Problem',
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'speechProblem',
    label: 'Speech Problem',
    type: FieldType.BOOLEANTEXT,
    placeholder: 'Speech Problem',
    templates: ['LAUTAN_CAHAYA_PERMATA'],
  },
  {
    name: 'personalStrength',
    label: 'Personal Strength',
    type: FieldType.TEXTAREA,
    templates: ['CMSI'],
  },
]
