import axios from 'axios'

export const tokenRetrievalRequest = () => {
  return axios.get(
    `${process.env.REACT_APP_PUBLIC_DOMAIN}/sanctum/csrf-cookie`,
    {
      withCredentials: true,
    }
  )
}
