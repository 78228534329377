import { Button, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { FormProps, INextOfKinItem } from './interface'
import ActionButtons from '../../ActionButtons'
import TableList from '../../../../components/TableList'
import ModalNextOfKinForm from '../../../../components/ModalNextOfKinForm'
import TableListTitle from '../../../../components/TableList/TableListTitle'
import { useConfirmation } from '../../../../context/ConfirmationContext'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import { IFormData } from '../../../../components/ModalNextOfKinForm/interface'

const NextOfKin: React.FC<FormProps> = ({ onBack, onNext, data = [] }) => {
  const {
    isOpen: isOpenForm,
    onOpen: onOpenForm,
    onClose: onCloseForm,
  } = useDisclosure()

  const [nextOfKins, setNextOfKins] = useState<INextOfKinItem[]>(
    data
      ? data.map((elm) => {
          return {
            rowItemId: uuidv4(),
            data: elm,
          }
        })
      : []
  )

  const { confirmation, close } = useConfirmation()

  const [selectedIndex, setSeletedIndex] = useState<number>(-1)
  const [selectedItem, setSelectedItem] = useState<INextOfKinItem | null>(null)

  const onLocalSubmit = (itemData: IFormData) => {
    let nextNextOfKins = null
    if (selectedItem !== null) {
      nextNextOfKins = update(nextOfKins, {
        [selectedIndex]: {
          data: { $set: itemData },
        },
      })
    } else {
      nextNextOfKins = update(nextOfKins, {
        $push: [{ rowItemId: uuidv4(), data: itemData }],
      })
    }

    setNextOfKins(nextNextOfKins)
    onCloseForm()
    setSelectedItem(null)
  }

  const deleteItem = (index: number) => {
    const nextNextOfKins = update(nextOfKins, {
      $splice: [[index, 1]],
    })

    setNextOfKins(nextNextOfKins)
  }

  return (
    <>
      <TableListTitle
        title="Next Of Kin List"
        actionComponents={
          <Button size="sm" onClick={onOpenForm}>
            Add Next Of Kin
          </Button>
        }
      />
      <TableList
        resources={nextOfKins}
        dataArrayRender={(nextOfKin) => {
          return [
            nextOfKin.data.name,
            nextOfKin.data.relation,
            nextOfKin.data.age,
            nextOfKin.data.city,
            nextOfKin.data.country,
          ]
        }}
        headings={[
          'Name',
          'Relation',
          'Age',
          'City',
          'Country'
        ]}
        actions={(nextOfKin, index) => (
          <Button
            size="sm"
            onClick={() => {
              setSeletedIndex(index)
              setSelectedItem(nextOfKin)
              onOpenForm()
            }}
          >
            Edit
          </Button>
        )}
        deleteFunc={(_, index) => {
          confirmation({
            description: 'Are you sure want to delete the data?',
            callback: () => {
              deleteItem(index)
              close()
            },
          })
        }}
      />
      <ActionButtons
        mt={10}
        onBack={onBack}
        onNext={() => onNext(nextOfKins.map((elm) => elm.data))}
      />
      <ModalNextOfKinForm
        data={selectedItem?.data}
        isOpen={isOpenForm}
        onClose={() => {
          onCloseForm()
          setSelectedItem(null)
        }}
        onSubmit={onLocalSubmit}
      />
    </>
  )
}

export default NextOfKin
