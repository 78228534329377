import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

export function useQueryParams<E>() {
  const location = useLocation()

  const { search } = location

  const [queryParams, setQueryParams] = useState<E | undefined>()

  useEffect(() => {
    const parsedQuery: any = queryString.parse(search)

    setQueryParams(
      Object.keys(parsedQuery).length >= 1 ? parsedQuery : undefined
    )
  }, [search])

  return {
    queryParams,
  }
}
