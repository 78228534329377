import { Box } from '@chakra-ui/layout'
import { Flex } from '@chakra-ui/react'
import { TableListTitleProps } from './interface'
import PageTitle from '../PageTitle'
import React from 'react'

const TableListTitle: React.FC<TableListTitleProps> = ({
  title,
  actionComponents,
}) => {
  return (
    <Box borderBottom="1px solid #dedede">
      <Box maxW="none" py="5" px="5">
        <Flex justifyContent="space-between" alignItems="center">
          <PageTitle mb="0" fontSize="1.6rem">
            {title}
          </PageTitle>
          <Box>{actionComponents}</Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default TableListTitle
