import React from 'react'
import { BoxProps, Flex } from '@chakra-ui/react'

const EmptyFullHeight: React.FC<BoxProps> = ({ bgColor, children }) => {
  return (
    <Flex
      h="100vh"
      bgColor={bgColor}
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Flex>
  )
}

export default EmptyFullHeight
