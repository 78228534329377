const Form = {
  parts: ['container'],
  baseStyle: {
    container: {
      mb: 5,
    },
  },
}

export default Form
