import { FormImageProps } from './inteface'
import ImageUploader from '../ImageUploder'
import { useState } from 'react'
import { useUpload } from '../../hooks/image'

const FormImage: React.FC<FormImageProps> = ({ preview = '', onChange }) => {
  const [previewImage, setPreviewImage] = useState(preview)
  const { uploadImage, isLoading } = useUpload()
  return (
    <ImageUploader
      accept="image/*"
      fileUrl={previewImage}
      isLoading={isLoading}
      onChange={async (file) => {
        setPreviewImage(URL.createObjectURL(file))
        const result = await uploadImage(file)
        onChange(result.data)
      }}
      onDelete={() => {
        setPreviewImage('')
        onChange(null)
      }}
    />
  )
}

export default FormImage
