const Button = {
  baseStyle: {
    fontWeight: '500',
  },
  sizes: {
    md: {
      borderRadius: '8px',
      fontSize: '18px',
      h: {
        base: '48px',
      },
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },
  variants: {
    solid: {
      bgColor: '#0c4f9f',
      color: '#fff',
      cursor: 'pointer',
      textAlign: 'center',
      _disabled: {
        bgColor: '#545455',
        color: '#C3C3C3',
      },
      _hover: {
        bgColor: '#0c4f9f',
      },
      _active: {
        bgColor: '#0e2c51',
      },
    },
    clean: {
      p: 0,
      fontSize: '1rem',
      fontWeight: 'normal',
      _hover: {
        bgColor: 'transparent',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
}

export default Button
