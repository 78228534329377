import React from 'react'
import Modal from '../Modal'
import { Box, Link, SimpleGrid } from '@chakra-ui/react'
import { templates } from '../../constants/cv'
import { ModalDownloadProps } from './interface'

const ModalDownload: React.FC<ModalDownloadProps> = ({
  item,
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      headerText="Download CV"
      isOpen={isOpen}
      onClose={onClose}
      withCloseButton={true}
    >
      <SimpleGrid columns={3} spacingX={5} spacingY={5}>
        {templates.map((template) => (
          <Box key={template.value}>
            <Link
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="100%"
              border="1px solid #333"
              href={`${process.env.REACT_APP_API_URL}/download-cv/${item?.id}/${template.path}`}
              px={3}
              py={2}
              target="_blank"
              _hover={{ textDecoration: 'none', backgroundColor: '#e8e8e8' }}
            >
              {template.label}
            </Link>
          </Box>
        ))}
      </SimpleGrid>
    </Modal>
  )
}

export default ModalDownload
