import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { AlertProviderProps, IAlertContext, IModalAlert } from './interface'

const AlertContext = React.createContext<IAlertContext>({
  alert: () => {},
  close: () => {},
})

const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [modalAlert, setModalAlert] = useState<IModalAlert | null>(null)

  const alert = (value: IModalAlert) => {
    setModalAlert(value)
    onOpen()
  }

  return (
    <AlertContext.Provider value={{ alert, close: onClose }}>
      {children}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalAlert?.title || 'Sukses'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>{modalAlert?.description}</Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AlertContext.Provider>
  )
}

export default AlertContext

const useAlert = () => useContext(AlertContext)

export { AlertProvider, useAlert }
