import { Button, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { FormProps, IEducationItem } from './interface'
import ActionButtons from '../../ActionButtons'
import TableList from '../../../../components/TableList'
import ModalEducationForm from '../../../../components/ModalEducationForm'
import TableListTitle from '../../../../components/TableList/TableListTitle'
import { useConfirmation } from '../../../../context/ConfirmationContext'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import { IFormData } from '../../../../components/ModalEducationForm/interface'
import moment from 'moment'

const Education: React.FC<FormProps> = ({ onBack, onNext, data = [] }) => {
  const {
    isOpen: isOpenForm,
    onOpen: onOpenForm,
    onClose: onCloseForm,
  } = useDisclosure()

  const [educations, setEducations] = useState<IEducationItem[]>(
    data
      ? data.map((elm) => {
          return {
            rowItemId: uuidv4(),
            data: elm,
          }
        })
      : []
  )

  const { confirmation, close } = useConfirmation()

  const [selectedIndex, setSeletedIndex] = useState<number>(-1)
  const [selectedItem, setSelectedItem] = useState<IEducationItem | null>(null)

  const onLocalSubmit = (itemData: IFormData) => {
    let nextEducations = null
    if (selectedItem !== null) {
      nextEducations = update(educations, {
        [selectedIndex]: {
          data: { $set: itemData },
        },
      })
    } else {
      nextEducations = update(educations, {
        $push: [{ rowItemId: uuidv4(), data: itemData }],
      })
    }

    setEducations(nextEducations)
    onCloseForm()
    setSelectedItem(null)
  }

  const deleteItem = (index: number) => {
    const nextEducations = update(educations, {
      $splice: [[index, 1]],
    })

    setEducations(nextEducations)
  }

  return (
    <>
      <TableListTitle
        title="Education List"
        actionComponents={
          <Button size="sm" onClick={onOpenForm}>
            Add Education
          </Button>
        }
      />
      <TableList
        resources={educations}
        dataArrayRender={(education) => {
          return [
            education.data.educationLevel,
            education.data.schoolName,
            moment(education.data.start).format('YYYY-MM-DD'),
            moment(education.data.end).format('YYYY-MM-DD'),
            education.data.highestStandardAttained,
          ]
        }}
        headings={[
          'Education Level',
          'School Name',
          'Start',
          'End',
          'Highest Standard Attained',
        ]}
        actions={(education, index) => (
          <Button
            size="sm"
            onClick={() => {
              setSeletedIndex(index)
              setSelectedItem(education)
              onOpenForm()
            }}
          >
            Edit
          </Button>
        )}
        deleteFunc={(_, index) => {
          confirmation({
            description: 'Are you sure want to delete the data?',
            callback: () => {
              deleteItem(index)
              close()
            },
          })
        }}
      />
      <ActionButtons
        mt={10}
        onBack={onBack}
        onNext={() => onNext(educations.map((elm) => elm.data))}
      />
      <ModalEducationForm
        data={selectedItem?.data}
        isOpen={isOpenForm}
        onClose={() => {
          onCloseForm()
          setSelectedItem(null)
        }}
        onSubmit={onLocalSubmit}
      />
    </>
  )
}

export default Education
