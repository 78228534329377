import { Button, SimpleGrid } from '@chakra-ui/react'
import { WorkingExperienceFormProps, IFormData } from './interface'
import { useForm } from 'react-hook-form'
import FormFields from '../FormFields'
import { useMemo } from 'react'
import { FieldType, ICvFormField } from '../../interfaces/cv'

const WorkingExperienceForm: React.FC<WorkingExperienceFormProps> = ({
  onSubmit,
  data,
}) => {
  const form = useForm<IFormData>({
    defaultValues: data || {},
  })
  
  const { handleSubmit, watch } = form

  const watchIsSeaService = watch('isSeaService')

  const fields = useMemo(() => {
    let items: ICvFormField[] = [
      {
        name: 'companyName',
        label: 'Company Name',
        type: FieldType.TEXT,
        templates: ['CMSI', 'ROYAL'],
      },
      {
        name: 'isSeaService',
        label: 'Is Sea Service',
        type: FieldType.BOOLEAN,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI'],
      },
    ]

    if (watchIsSeaService) {
      items = items.concat([
        {
          name: 'vesselName',
          label: 'Vessel Name',
          type: FieldType.TEXT,
          templates: ['LAUTAN_CAHAYA_PERMATA'],
        },
        {
          name: 'vesselFlag',
          label: 'Vessel Flag',
          type: FieldType.TEXT,
          templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI'],
        },
        {
          name: 'vesselType',
          label: 'Vessel Type',
          type: FieldType.TEXT,
          templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI'],
        },
        {
          name: 'vesselRank',
          label: 'Vessel Rank',
          type: FieldType.TEXT,
          templates: ['CMSI'],
        },
        {
          name: 'vesselGrt',
          label: 'Vessel GRT',
          type: FieldType.TEXT,
          templates: ['CMSI'],
        },
      ])
    } else {
      items = items.concat([
        {
          name: 'companyType',
          label: 'Company Type',
          type: FieldType.SELECT,
          options: [
            {
              value: 'Hotel',
              label: 'Hotel',
            },
            {
              value: 'Restaurant',
              label: 'Restaurant',
            },
          ],
          templates: ['LAUTAN_CAHAYA_PERMATA'],
        },
      ])
    }

    items = items.concat([
      {
        name: 'companyLocation',
        label: 'Company Location',
        type: FieldType.TEXT,
        templates: ['ROYAL'],
      },
      {
        name: 'position',
        label: 'Position',
        type: FieldType.TEXT,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'ROYAL'],
      },
      {
        name: 'start',
        label: 'Start Date',
        type: FieldType.DATEPICKER,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'end',
        label: 'End Date',
        type: FieldType.DATEPICKER,
        templates: ['LAUTAN_CAHAYA_PERMATA', 'CMSI', 'ROYAL'],
      },
      {
        name: 'jobDesc',
        label: 'Job Desc',
        type: FieldType.TEXTAREA,
        templates: ['LAUTAN_CAHAYA_PERMATA'],
      },
      {
        name: 'salary',
        label: 'Salary',
        type: FieldType.TEXT,
        templates: ['ROYAL'],
      },
      {
        name: 'remarks',
        label: 'Remarks',
        type: FieldType.TEXT,
        templates: ['CMSI'],
      },
      {
        name: 'leavingReason',
        label: 'Leaving Reason',
        type: FieldType.TEXTAREA,
        templates: ['ROYAL'],
      },
    ])

    return items
  }, [watchIsSeaService])

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <SimpleGrid columns={2} spacing={5}>
        <FormFields fields={fields} hookForm={form} />
      </SimpleGrid>

      <Button type="submit">Submit</Button>
    </form>
  )
}

export default WorkingExperienceForm
