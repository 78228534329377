import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react'
import PageTitle from '../../components/PageTitle'
import CardForm from '../../components/CardForm'
import { IProfileFormData } from './interface'
import { useAuth } from '../../context/AuthContext'
import {
  useUpdatePasswordMutation,
  useUpdateProfileMutation,
} from '../../generated/graphql'

const Me = () => {
  const { me } = useAuth()

  const toast = useToast()

  const {
    register: registerProfile,
    handleSubmit: handleSubmitProfile,
    formState: { errors: errorsProfile },
    setValue: setValueProfile,
  } = useForm<IProfileFormData>()

  useEffect(() => {
    if (me) {
      setValueProfile('name', me.name)
    }
  }, [me, setValueProfile])

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
    watch: watchPassword,
    reset: resetPassword,
  } = useForm()

  const [updateProfile, { loading: loadingProfile }] =
    useUpdateProfileMutation()

  const onChangeProfile = async (formData: IProfileFormData) => {
    const { name } = formData

    await updateProfile({
      variables: {
        input: {
          name,
        },
      },
    })

    toast({
      description: 'Update Profile Successfully!',
      status: 'success',
    })
  }

  const [updatePassword, { loading: loadingPassword }] =
    useUpdatePasswordMutation()

  const onChangePassword = async (formData: any) => {
    const { newPassword, oldPassword } = formData

    try {
      await updatePassword({
        variables: {
          input: {
            password: newPassword,
            oldPassword: oldPassword,
          },
        },
      })

      toast({
        description: 'Update Password Successfully!',
        status: 'success',
      })

      resetPassword()
    } catch (err: any) {
      toast({
        description:
          err.message === 'wrong_password'
            ? 'The Old Password is Wrong!'
            : 'Error',
        status: 'error',
      })
    }
  }

  return (
    <Box maxW="50%" mx="auto">
      <CardForm>
        <PageTitle>Akun</PageTitle>
        <form
          onSubmit={handleSubmitProfile(onChangeProfile)}
          autoComplete="off"
        >
          <FormControl isInvalid={errorsProfile.hasOwnProperty('name')}>
            <FormLabel>Your Name</FormLabel>
            <Input
              id="name"
              placeholder="Enter your name"
              {...registerProfile('name', { required: true })}
            />
            <FormErrorMessage>
              {errorsProfile.name && 'Name is required!'}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <Button
              isDisabled={loadingProfile}
              isLoading={loadingProfile}
              type="submit"
            >
              Save
            </Button>
          </FormControl>
        </form>
      </CardForm>

      <CardForm mt="3rem">
        <PageTitle>Change Password</PageTitle>
        <form
          onSubmit={handleSubmitPassword(onChangePassword)}
          autoComplete="off"
        >
          <FormControl isInvalid={errorsPassword.hasOwnProperty('newPassword')}>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              placeholder="Enter new password"
              {...registerPassword('newPassword', { required: true })}
            />
            <FormErrorMessage>
              {errorsPassword.newPassword && 'New Password cannot be empty!'}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={errorsPassword.hasOwnProperty('confirmNewPassword')}
          >
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              placeholder="Confirm new password"
              {...registerPassword('confirmNewPassword', {
                required: true,
                validate: (value) => value === watchPassword('newPassword'),
              })}
            />
            <FormErrorMessage>
              {errorsPassword.confirmNewPassword &&
                errorsPassword.confirmNewPassword.type === 'required' &&
                'Confirm password is required!'}

              {errorsPassword.confirmNewPassword &&
                errorsPassword.confirmNewPassword.type === 'validate' &&
                'Password is not same!'}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errorsPassword.hasOwnProperty('oldPassword')}>
            <FormLabel>Old Password</FormLabel>
            <Input
              type="password"
              placeholder="Enter old password"
              {...registerPassword('oldPassword', { required: true })}
            />
            <FormErrorMessage>
              {errorsPassword.oldPassword && 'Old Password cannot be empty!'}
            </FormErrorMessage>
          </FormControl>

          <FormControl>
            <Button
              isDisabled={loadingPassword}
              isLoading={loadingPassword}
              type="submit"
            >
              Update Password
            </Button>
          </FormControl>
        </form>
      </CardForm>
    </Box>
  )
}

export default Me
