import React, { useContext, useEffect, useState } from 'react'
import { IAuthContext, AuthProviderProps } from './interface'
import { User, useMeQuery } from '../../generated/graphql'

const AuthContext = React.createContext<IAuthContext>({
  me: null,
  setMe: () => {},
  isFetched: true,
  isLogin: false,
  setIsLogin: () => {},
})

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [me, setMe] = useState<User | null>(null)
  const [isFetched, setIsFetched] = useState(false)
  const [isLogin, setIsLogin] = useState(false)

  const { data, loading } = useMeQuery()

  useEffect(() => {
    setIsFetched(!loading)
  }, [loading])

  useEffect(() => {
    if (data?.me) {
      setMe(data.me)
      setIsLogin(true)
    }
  }, [data])

  return (
    <AuthContext.Provider
      value={{
        me,
        setMe,
        isFetched,
        setIsLogin,
        isLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
