import { Container } from '@chakra-ui/react'
import PageTitle from '../../components/PageTitle'

const NotFound = () => {
  return (
    <Container>
      <PageTitle>Not Found</PageTitle>
    </Container>
  )
}

export default NotFound
