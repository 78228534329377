import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { InputGroup, Input } from '@chakra-ui/react'
import { IUserFilter } from './interface'

const UserFilter: React.FC<IUserFilter> = ({ onSubmit, value }) => {
  const { register, handleSubmit, setValue } = useForm()

  useEffect(() => {
    let key: string | undefined = undefined

    if (value) {
      const { key: _key } = value

      key = _key
    }

    setValue('key', key)
  }, [value, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputGroup>
        <Input
          id="key"
          placeholder="User Name or Email"
          {...register('key')}
          size="sm"
        />
      </InputGroup>
    </form>
  )
}

export default UserFilter
