import { ModalEducationFormProps } from './interface'
import EducationForm from './EducationForm'
import ModalComp from '../Modal'

const ModalEducationForm: React.FC<ModalEducationFormProps> = ({
  isOpen,
  onClose,
  ...props
}) => {
  return (
    <ModalComp isOpen={isOpen} onClose={onClose} headerText="Education">
      <EducationForm {...props} />
    </ModalComp>
  )
}

export default ModalEducationForm
